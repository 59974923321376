import React from 'react';
import LoginForm from '../../components/loginForm';
import BasicLayout from '../../components/basicLayout';

const Login = () => {
  const handleSubmit = (form) => console.log(form);

  return (
    <BasicLayout>
      <LoginForm onSubmit={handleSubmit} />
    </BasicLayout>
  );
};

export default Login;
