import React from 'react';
import { Form } from 'semantic-ui-react';
// 'retail', 'restaurant', 'food&drink', 'other'
import style from './dropdown.module.scss';

const Dropdown = (props) => {
  const {
    options,
    placeholder,
    search,
    input,
    meta: {
      error,
      touched,
    },
  } = props;
  return (
    <>
      <Form.Dropdown
        placeholder={placeholder}
        fluid
        selection
        options={options}
        search={search}
        onChange={(_, data) => input.onChange(data.value)}
        value={input.value}
      />
      <div className={style.error}>{touched && error}</div>
    </>
  );
};

Dropdown.defaultProps = ({
  placeholder: '',
  options: [],
  search: false,
});

export default Dropdown;
