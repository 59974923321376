import {
  Grid, Icon, Segment, Table,
} from 'semantic-ui-react';
import React from 'react';
import headerGroupStyle from './header-group.module.scss';
import { capitalize } from '../../lib/common';

const getOrderItem = ({
  name, quantity, price, id,
}) => {
  return (
    <Table.Row key={id}>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell textAlign="center">{quantity}</Table.Cell>
      <Table.Cell textAlign="right">
        $
        {price}
      </Table.Cell>
    </Table.Row>
  );
};

const OrderDetailsView = ({ order }) => {
  return (
    <Segment raised>
      <Grid>
        <Grid.Row style={{ padding: '0px 0px 14px 0' }}>
          <Grid.Column
            computer={8}
            tablet={16}
            style={{
              padding: '20px',
              backgroundColor: '#F9FAFB',
              borderColor: '#E4E5E5',
              borderStyle: 'solid',
              borderWidth: '0 1px 1px 0',
            }}
          >
            <div className={headerGroupStyle['block-left-content']}>
              <div>Date</div>
              <div style={{ marginTop: '5px', fontWeight: 'bold' }}>{new Date(order.createdAt).toLocaleString()}</div>
            </div>
            <div style={{ float: 'right' }}><Icon name="calendar alternate outline" size="big" /></div>
          </Grid.Column>
          <Grid.Column
            computer={4}
            tablet={8}
            style={{
              padding: '20px',
              backgroundColor: '#F9FAFB',
              borderColor: '#E4E5E5',
              borderStyle: 'solid',
              borderWidth: '0 1px 1px 0',
            }}
          >
            <div className={headerGroupStyle['block-left-content']}>
              <div>Ordered by</div>
              <div style={{ marginTop: '5px', fontWeight: 'bold' }}>
                {`${capitalize(order.customer.firstName)} ${capitalize(order.customer.lastName)}`}
              </div>
            </div>
            <div style={{ float: 'right' }}><Icon name="user" size="big" /></div>
          </Grid.Column>
          {/* <Grid.Column
                  computer={4}
                  tablet={8}
                  style={{
                    padding: '20px',
                    backgroundColor:'#F9FAFB',
                    borderColor: '#E4E5E5',
                    borderStyle: 'solid', borderWidth: '0 1px 1px 0',
                  }}
                >
                  <div className={headerGroupStyle['block-left-content']}>
                    <div>Address</div>
                    <div style={{ marginTop: '5px', fontWeight: 'bold' }}>
                      Slaves Rd., No. 66, Mos Espa, Tatooine
                    </div>
                  </div>
                  <div style={{ float: 'right' }}>
                    <Icon name="map marker alternate" size="big" />
                  </div>
                </Grid.Column> */}
          <Grid.Column
            computer={4}
            tablet={8}
            style={{
              padding: '20px',
              backgroundColor: '#F9FAFB',
              borderColor: '#E4E5E5',
              borderStyle: 'solid',
              borderWidth: '0 0 1px 0',
            }}
          >
            <div className={headerGroupStyle['block-left-content']}>
              <div>Timing</div>
              <div style={{ marginTop: '5px', fontWeight: 'bold' }}>{capitalize(order.timeType)}</div>
            </div>
            <div style={{ float: 'right' }}><Icon name="clock outline" size="big" /></div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Item</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Qta</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Subtotal</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {/* <Table.Row>
                  <Table.Cell>
                    <div>Hamburger</div>
                    <div style={{
                      fontSize: '12px', color: 'gray', padding: '5px 0 0 10px', fontWeight: '600',
                    }}
                    >
                      + cheese
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="center">1</Table.Cell>
                  <Table.Cell textAlign="right">
                    <div>$ 19.95</div>
                    <div style={{
                      fontSize: '12px', color: 'gray', padding: '5px 0 0 10px', fontWeight: '600',
                    }}
                    >
                      $ 1
                    </div>
                  </Table.Cell>
                  </Table.Row> */}
          {order.orderItems.map((item) => getOrderItem(item))}
          <Table.Row>
            <Table.Cell colSpan="3" />
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="3" textAlign="right">
              Subtotal:
              <span style={{ display: 'inline-block', width: '150px' }}>
                $
                {order.subtotal}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="3" textAlign="right">
              Discount:
              <span style={{ display: 'inline-block', width: '150px' }}>
                $
                {order.discount}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="3" textAlign="right">
              Tips:
              <span style={{ display: 'inline-block', width: '150px' }}>
                $
                {order.tips}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="3" textAlign="right">
              Tax:
              <span style={{ display: 'inline-block', width: '150px' }}>
                $
                {order.tax}
              </span>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="3" textAlign="right">
              Total:
              <span style={{
                display: 'inline-block', width: '150px', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                $
                {' '}
                {order.total}
              </span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  );
};

export default OrderDetailsView;
