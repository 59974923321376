import React from 'react';
import {
  Segment,
} from 'semantic-ui-react';
import { SortableContainer } from 'react-sortable-hoc';
import ItemMenu from './item';

class SortableList extends React.Component {
  render() {
    const { items, collection } = this.props;
    return (
      <Segment.Group>
        {
          items.map((item, index) => {
            return (
              <ItemMenu
                key={item._id}
                index={index}
                collection={collection}
                {...item}
              />
            );
          })
        }
      </Segment.Group>
    );
  }
}

export default SortableContainer(SortableList);
