import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Form,
  Grid,
  Header,
  Divider,
  Segment,
  Message,
} from 'semantic-ui-react';
import Input from '../input';
import Dropdown from '../dropdown';
import style from './sign-up-form.module.scss';
import countries from '../../constants/countries.json';

const businessTypeOptions = [
  { key: 'retail', text: 'Retail', value: 'retail' },
  { key: 'restaurant', text: 'Restaurant', value: 'restaurant' },
  { key: 'food&drink', text: 'Food & Drink', value: 'food&drink' },
  { key: 'other', text: 'Other', value: 'other' },
];

const SignUpForm = ({ handleSubmit }) => (
  <Grid textAlign="center" verticalAlign="middle">
    <Grid.Column style={{ maxWidth: 450 }}>
      <Header as="h1" color="blue" textAlign="center">
        Register a new account
      </Header>
      <Form size="large" onSubmit={handleSubmit}>
        <Segment>
          <Header as="h3" color="blue">Owner Info</Header>
          <Field
            component={Input}
            name="firstName"
            placeholder="First Name"
          />
          <Field
            component={Input}
            name="lastName"
            placeholder="Last Name"
          />
          <Field
            component={Input}
            name="email"
            placeholder="E-mail address"
          />
          <Field
            component={Input}
            name="phone"
            placeholder="Phone"
          />
          <Divider hidden />
          <Header as="h3" color="blue">Business Info</Header>
          <Field
            component={Input}
            name="businessName"
            placeholder="Name"
          />
          <Field
            component={Dropdown}
            name="businessType"
            placeholder="Business type"
            options={businessTypeOptions}
          />
          <Field
            component={Input}
            name="address1"
            placeholder="Address 1"
          />
          <Field
            component={Input}
            name="address2"
            placeholder="Address 2"
          />
          <Field
            component={Input}
            name="city"
            placeholder="City"
          />
          <Field
            component={Input}
            name="state"
            placeholder="State"
          />
          <Field
            component={Input}
            name="zip"
            placeholder="ZIP"
          />
          <Field
            component={Dropdown}
            name="country"
            placeholder="Country"
            options={countries}
            search
          />
          <Button color="blue" fluid size="large">
            Sign Up
          </Button>
          <div className={style.assistance}>
            need assistance? mailto cs@email.com or call +xxxxx
          </div>
        </Segment>
      </Form>
      <Message>
        Already a client?
        <a href="/sign-in"> Login Here</a>
      </Message>
    </Grid.Column>
  </Grid>
);

export default reduxForm({ form: 'signUp' })(SignUpForm);
