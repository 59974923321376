import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import {
  MOKA_START_INSTALL,
  mokaStartInstallFailure,
  mokaStartInstallSuccess,
} from '../reducers/install';
import { mokaStartInstall } from '../constants/api/install/moka';
import { storeAuthToken } from './auth';

function* mokaStartInstallRequest({ payload: code }) {
  try {
    const { data, headers } = yield call(mokaStartInstall, code);

    yield put(mokaStartInstallSuccess({ businessId: data.data.id }));
    yield put(storeAuthToken(headers.authorization));
  } catch (e) {
    yield put(mokaStartInstallFailure());
  }
}

export function* watchingMokaStartInstall() {
  yield takeLatest(MOKA_START_INSTALL, mokaStartInstallRequest);
}
