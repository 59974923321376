import { call, put, takeLatest } from '@redux-saga/core/effects';
import { fetchLocale } from '../constants/locale';
import {
  GET_LOCALE,
  getLocaleSuccess,
  getLocaleFailure,
} from '../lib/locale/reducer';

function* getLocale({ payload: locale }) {
  try {
    const { data: messages } = yield call(fetchLocale, locale);
    yield put(getLocaleSuccess({ messages, locale }));
  } catch (e) {
    yield put(getLocaleFailure());
  }
}

export function* watchGetLocale() {
  yield takeLatest(GET_LOCALE, getLocale);
}
