import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Header,
  Divider,
  Segment,
  Button,
  Message,
} from 'semantic-ui-react';
import arrayMove from 'array-move';
import SortableList from './sortableContainer';
import { updateItemsOrderInfo } from '../../../sagas/inventory';
import FormattedText from '../../../lib/locale/formattedText';

class Category extends React.Component {
  state = {
    collections: [],
  }

  componentDidMount() {
    this.setState({ collections: this.props.categories.map((category) => category.items) });
  }

  onSortEnd = ({ oldIndex, newIndex, collection }) => {
    this.setState(({ collections }) => {
      const newCollections = [...collections];

      newCollections[collection] = arrayMove(
        collections[collection],
        oldIndex,
        newIndex,
      );

      return { collections: newCollections };
    });
  };

  handleSave = () => {
    const { collections } = this.state;
    const preparedData = collections.reduce((acc, items) => {
      const preparedItems = items.map((item, index) => ({
        sortItem: index,
        itemId: item.ItemCategory.itemId,
        categoryId: item.ItemCategory.categoryId,
      }));
      return acc.concat(preparedItems);
    }, []);

    return this.props.updateItemsOrderInfo(preparedData);
  }

  render() {
    const { collections } = this.state;
    const { categories, submit } = this.props;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={{ marginLeft: '10px' }}>
            <Header as="h1" color="blue" textAlign="left">
              <FormattedText id="items" defaultMessage="Items" />
            </Header>
            <div>
              <FormattedText id="page.items.subtitle" defaultMessage="You can drag and drop the items to change the order, then click on the save button!" />
            </div>
            <Divider />
            <Button primary onClick={this.handleSave}>
              <FormattedText id="save" defaultMessage="Save" />
            </Button>
            {submit.success && <Message color="green" align="center">{submit.message}</Message>}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {
              collections.map((items, index) => {
                return (
                  <Segment.Group raised key={index}>
                    <Segment as="h5" size="big" secondary={items.length === 0}>{categories[index].name}</Segment>
                    {
                      items.length > 0
                      && (
                      <SortableList
                        items={items}
                        onSortEnd={this.onSortEnd}
                        pressDelay={200}
                        collection={index}
                      />
                      )
                    }
                  </Segment.Group>
                );
              })
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    categories: state.inventory.categories,
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateItemsOrderInfo: (data) => dispatch(updateItemsOrderInfo(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
