import { fork, all } from 'redux-saga/effects';
import { watchingGetMerchantInfo } from './merchant';
import { watchingMokaStartInstall } from './install';
import { watchRedirect } from './redirect';
import { watchingAuthLogout, watchingStoreToken } from './auth';
import { watchInventory } from './inventory';
import { watchGetLocale } from './locale';
import { watchGetOrders } from './orders';
import { watchLocation } from './location';

export default function* rootSaga() {
  yield all([
    fork(watchingGetMerchantInfo),
    fork(watchingMokaStartInstall),
    fork(watchRedirect),
    fork(watchingAuthLogout),
    fork(watchingStoreToken),
    fork(watchInventory),
    fork(watchGetLocale),
    fork(watchGetOrders),
    fork(watchLocation),
  ]);
};
