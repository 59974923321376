import React, { useEffect, useState } from 'react';
import {
  Grid,
  Header,
  Divider,
  Container,
  Input,
  Dropdown,
  Button,
} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import OrdersTable from './ordersTable';
// import orders from './sample';
import delay from '../../../lib/debounce';
import style from './orders.module.scss';
import { getOrders } from '../../../sagas/orders';
import setQueryString from '../../../lib/queryString';
import FormattedText from '../../../lib/locale/formattedText';

const tagOptions = [
  {
    key: 'all',
    text: 'All',
    value: 'all',
  },
  {
    key: 'pending',
    text: 'Pending',
    value: 'pending',
  },
  {
    key: 'accepted',
    text: 'Accepted',
    value: 'accepted',
  },
  {
    key: 'closed',
    text: 'Closed',
    value: 'closed',
  },
  {
    key: 'cancelled',
    text: 'Cancelled',
    value: 'cancelled',
  },
  {
    key: 'rejected',
    text: 'Rejected',
    value: 'rejected',
  },
  {
    key: 'completed',
    text: 'Completed',
    value: 'completed',
  },
];

const Orders = ({
  push, fetchOrders, orders, locations,
}) => {
  const [search, setSearch] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [location, setLocation] = useState([]);
  const handleSearch = (event, data) => setSearch(data.value);
  const handleFilterStatus = (event, data) => setStatus(data.value === 'all' ? null : data.value);
  const handleFilterLocation = (event, data) => setLocation(data.value === 'all' ? [] : [data.value]);
  const handleSetStartDate = (event, data) => {
    const timestamp = new Date(data.value).getTime();
    return setStartDate(timestamp);
  };
  const handleSetEndDate = (event, data) => {
    const timestamp = new Date(data.value).getTime();
    return setEndDate(timestamp);
  };
  const queryString = setQueryString({
    search,
    startDate: startDate ? new Date(startDate).toISOString() : null,
    endDate: endDate ? new Date(endDate).toISOString() : null,
    status,
    locations: location.length > 0 ? location : locations.map((location) => location._id),
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchOrders(queryString);
  };

  useEffect(() => {
    fetchOrders(queryString);
    /* eslint-disable-next-line */
  }, [fetchOrders]);

  const getLocationsOptions = () => {
    return [
      {
        key: 'all',
        text: 'All',
        value: 'all',
      },
      ...locations.map((location) => ({
        key: location._id,
        text: location.name,
        value: location._id,
      })),
    ];
  };

  const intl = useIntl();

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} style={{ marginLeft: '10px' }}>
          <Header as="h1" color="blue" textAlign="left">
            <FormattedText id="orders" defaultMessage="Orders" />
          </Header>
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Container>
          <div className={style['date-picker']}>
            <SemanticDatepicker
              onChange={handleSetStartDate}
              placeholder={intl.formatMessage({ id: 'start-date', defaultMessage: 'Start date' })}
              iconPosition="left"
            />
          </div>
          <div className={style['date-picker']}>
            <SemanticDatepicker
              onChange={handleSetEndDate}
              placeholder={intl.formatMessage({ id: 'end-date', defaultMessage: 'End date' })}
              iconPosition="left"
            />
          </div>
          <div className={style['input-search']}>
            <Input
              placeholder={intl.formatMessage({ id: 'input.order-id', defaultMessage: 'Type order ID' })}
              fluid
              onChange={delay(handleSearch)}
            />
          </div>
          <Dropdown
            style={{ margin: '0 10px 10px 0' }}
            placeholder="Status"
            search
            selection
            options={tagOptions}
            onChange={handleFilterStatus}
          />
          <Dropdown
            style={{ margin: '0 10px 10px 0' }}
            placeholder={intl.formatMessage({ id: 'location', defaultMessage: 'Location' })}
            search
            selection
            options={getLocationsOptions()}
            onChange={handleFilterLocation}
          />
          <div className={style['search-btn']}>
            <Button
              labelPosition="right"
              icon="search"
              content={intl.formatMessage({ id: 'search', defaultMessage: 'Search' })}
              color="blue"
              onClick={handleSubmit}
              type="submit"
            />
          </div>
          <OrdersTable
            orders={orders.rows}
            count={orders.count}
            redirect={push}
            size="large"
            fetchOrders={fetchOrders}
            queryString={queryString}
          />
        </Container>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    locations: state.merchant.locations.map((location) => ({
      _id: location._id,
      name: location.name,
    })),
    orders: state.orders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (data) => dispatch(push(data)),
    fetchOrders: (data) => dispatch(getOrders(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
