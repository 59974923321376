import { createAction } from 'redux-actions';
import {
  call,
  takeLatest,
  put,
  select,
} from 'redux-saga/effects';
import { fetchOrdersInfo, fetchOrderDetailsApi, putOrderStateApi } from '../constants/api/orders';

const PREFIX = 'SAGAS/ORDERS';
export const GET_ORDERS = `${PREFIX}/GET_ORDERS`;
export const GET_ORDERS_SUCCESS = `${PREFIX}/GET_ORDERS_SUCCESS`;
export const GET_ORDERS_FAILURE = `${PREFIX}/GET_ORDERS_FAILURE`;

export const getOrders = createAction(GET_ORDERS);
export const getOrdersSuccess = createAction(GET_ORDERS_SUCCESS);
export const getOrdersFailure = createAction(GET_ORDERS_FAILURE);

export const GET_ORDER_DETAILS = `${PREFIX}/GET_ORDER_DETAILS`;
export const GET_ORDER_DETAILS_SUCCESS = `${PREFIX}/GET_ORDER_DETAILS_SUCCESS`;
export const GET_ORDER_DETAILS_FAILURE = `${PREFIX}/GET_ORDER_DETAILS_FAILURE`;

export const getOrderDetails = createAction(GET_ORDER_DETAILS);
export const getOrderDetailsSuccess = createAction(GET_ORDER_DETAILS_SUCCESS);
export const getOrderDetailsFailure = createAction(GET_ORDER_DETAILS_FAILURE);

export const UPDATE_ORDER_STATE = `${PREFIX}/UPDATE_ORDER_STATE`;
export const UPDATE_ORDER_STATE_SUCCESS = `${PREFIX}/UPDATE_ORDER_STATE_SUCCESS`;
export const UPDATE_ORDER_STATE_FAILURE = `${PREFIX}/UPDATE_ORDER_STATE_FAILURE`;


export const updateOrderState = createAction(UPDATE_ORDER_STATE);
export const updateOrderStateSuccess = createAction(UPDATE_ORDER_STATE_SUCCESS);
export const updateOrderStateFailure = createAction(UPDATE_ORDER_STATE_FAILURE);

function* fetchOrders({ payload: query }) {
  const { _id: merchantId } = yield select((state) => state.merchant.business);
  try {
    const { data: { data: orders } } = yield call(fetchOrdersInfo, query, merchantId);
    yield put(getOrdersSuccess(orders));
  } catch (e) {
    yield put(getOrdersFailure());
  }
}

function* fetchOrderDetails({ payload }) {
  const { orderId, locationId } = payload;
  try {
    const { data: { data: order } } = yield call(fetchOrderDetailsApi, orderId, locationId);
    yield put(getOrderDetailsSuccess(order));
  } catch (e) {
    yield put(getOrderDetailsFailure());
  }
}

function* putOrderState({ payload }) {
  const { state, orderId, locationId } = payload;
  try {
    const { data: { data: order } } = yield call(putOrderStateApi, orderId, locationId, state);
    yield put(updateOrderStateSuccess(order));
  } catch (e) {
    yield put(updateOrderStateFailure());
  }
}

export function* watchGetOrders() {
  yield takeLatest(GET_ORDERS, fetchOrders);
  yield takeLatest(GET_ORDER_DETAILS, fetchOrderDetails);
  yield takeLatest(UPDATE_ORDER_STATE, putOrderState);
}
