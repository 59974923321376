import React from 'react';
import {
  Table,
  Button,
} from 'semantic-ui-react';
import * as PATH from '../../../constants/path';
import FormattedText from '../../../lib/locale/formattedText';

const LocationsTable = ({ locations, redirect, handleSync }) => {
  const goToOrderDetail = (e) => {
    e.preventDefault();
    if (e.target.nodeName === 'TD') {
      const locationId = e.target.parentNode.id;
      return redirect(`${PATH.DASHBOARD_LOCATIONS}/${locationId}`);
    }

    return false;
  };

  return (
    <Table celled selectable striped color="blue" padded>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedText id="name" defaultMessage="Name" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedText id="address" defaultMessage="Address" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedText id="opening-time" defaultMessage="Opening Time" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedText id="accept-orders" defaultMessage="Accept Orders" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedText id="sync-inventory" defaultMessage="Sync Inventory" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body onClick={goToOrderDetail}>
        {
          locations.map((location) => {
            return (
              <Table.Row key={location._id} id={location._id} style={{ cursor: 'pointer' }}>
                <Table.Cell>{location.name}</Table.Cell>
                <Table.Cell>{`${location.address1} ${location.city} ${location.city} ${location.zip}`}</Table.Cell>
                <Table.Cell>{`${location.openTime} ~ ${location.closeTime}`}</Table.Cell>
                <Table.Cell positive={location.acceptOrder} negative={!location.acceptOrder}>{location.acceptOrder ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Button color="blue" onClick={() => handleSync(location._id)}>
                    <FormattedText id="sync" defaultMessage="Sync" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default LocationsTable;
