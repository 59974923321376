import React from 'react';
import { Checkbox } from 'semantic-ui-react';

const Toggle = (props) => {
  const {
    input: { value, onChange, ...input },
    meta,
    ...rest
  } = props;

  return (
    <div style={{ paddingBottom: '16px' }}>
      <span style={{ float: 'left' }}>{rest.placeholder || input.name}</span>
      <Checkbox
        {...input}
        {...rest}
        checked={!!value}
        onChange={(e, data) => onChange(data.checked)}
        style={{ float: 'right' }}
        toggle
      />
    </div>
  );
};

export default Toggle;
