import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import Input from '../input';

const LoginForm = ({ handleSubmit }) => (
  <Grid textAlign="center" verticalAlign="middle">
    <Grid.Column style={{ maxWidth: 450 }}>
      <Header as="h2" color="blue" textAlign="center">
        Log-in to your account
      </Header>
      <Form size="large" onSubmit={handleSubmit}>
        <Segment>
          <Field
            component={Input}
            name="email"
            icon="user"
            placeholder="E-mail address"
            iconPosition="left"
          />
          <Field
            component={Input}
            name="password"
            icon="lock"
            placeholder="Password"
            type="password"
            iconPosition="left"
          />
          <Button color="blue" fluid size="large">
            Login
          </Button>
        </Segment>
      </Form>
      <Message>
        New merchant?
        <a href="/sign-up"> Register Here</a>
      </Message>
    </Grid.Column>
  </Grid>
);

export default reduxForm({ form: 'signIn' })(LoginForm);
