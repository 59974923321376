import React from 'react';
import ResponsiveNav from './responsiveNav';
import style from './dashboard-layout.module.scss';


export default class DashboardLayout extends React.Component {
  render() {
    const {
      children,
      logoutUser,
    } = this.props;
    return (
      <>
        <ResponsiveNav logoutUser={logoutUser} />
        <div className={style.content}>{children}</div>
      </>
    );
  }
}
