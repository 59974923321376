import React from 'react';
import {
  Container,
  Menu,
  Responsive,
  Visibility,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { getWidth } from './helpers';
import MenuItems from './menuItems';
import logo from '../../../assets/logo.svg';


class DesktopNav extends React.Component {
  handleItemClick = (e, { route }) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.history.push(route);
  }

  render() {
    const { children, pathname, logoutUser } = this.props;

    return (
      <Responsive
        getWidth={getWidth}
        minWidth={Responsive.onlyTablet.minWidth}
      >
        <Visibility>
          <Menu
            vertical
            fixed="top"
            size="large"
            inverted
            style={{ height: '100%' }}
            icon="labeled"
          >
            <Container>
              <img src={logo} style={{ width: '150px', padding: '30px 15px 30px 15px', margin: 'auto' }} alt="logo" />
              <MenuItems
                handleItemClick={this.handleItemClick}
                pathname={pathname}
                logoutUser={logoutUser}
              />
            </Container>
          </Menu>
        </Visibility>
        {children}
      </Responsive>
    );
  }
}

export default withRouter(DesktopNav);
