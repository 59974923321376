import { handleActions, combineActions } from 'redux-actions';
import { GET_MERCHANT_INFO_SUCCESS } from './merchant';
import {
  UPDATE_ITEMS_ORDER_INFO_SUCCESS,
  UPDATE_CATEGORIES_ORDER_INFO_SUCCESS,
  UPDATE_CATEGORY_INFO_SUCCESS,
  UPDATE_ITEM_INFO_SUCCESS,
  UPDATE_MODIFIERS_ORDER_INFO_SUCCESS,
  UPDATE_MODIFIER_INFO_SUCCESS,
  UPDATE_MODIFIER_GROUP_INFO_SUCCESS,
  GET_SYNC_INVENTORY_SUCCESS,
  UPDATE_VARIANT_INFO_SUCCESS,
} from '../sagas/inventory';

export const defaultState = {
  categories: [],
  modifierGroups: [],
};

export const reducer = handleActions(
  {
    [GET_MERCHANT_INFO_SUCCESS]: (state, { payload: { inventory } }) => {
      return {
        ...state,
        categories: inventory.categories,
        modifierGroups: inventory.modifierGroups,
      };
    },
    [UPDATE_CATEGORY_INFO_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        categories: state.categories.map(
          (category) => category._id === payload._id ? payload : category,
        ),
      };
    },
    [UPDATE_MODIFIER_GROUP_INFO_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        modifierGroups: state.modifierGroups.map(
          (modifierGroup) => modifierGroup._id === payload._id
            ? payload
            : modifierGroup,
        ),
      };
    },
    [UPDATE_ITEM_INFO_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        categories: state.categories.map((category) => {
          return {
            ...category,
            items: category.items.map(
              (item) => item._id === payload._id
                ? { ...payload, ItemCategory: item.ItemCategory, variants: item.variants }
                : item,
            ),
          };
        }),
      };
    },
    [UPDATE_MODIFIER_INFO_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        modifierGroups: state.modifierGroups.map((modifierGroup) => {
          return {
            ...modifierGroup,
            modifiers: modifierGroup.modifiers.map(
              (modifier) => modifier._id === payload._id
                ? payload
                : modifier,
            ),
          };
        }),
      };
    },
    [combineActions(
      UPDATE_ITEMS_ORDER_INFO_SUCCESS,
      UPDATE_CATEGORIES_ORDER_INFO_SUCCESS,
      UPDATE_MODIFIERS_ORDER_INFO_SUCCESS,
    )]: (state, { payload }) => {
      return {
        ...state,
        categories: payload.categories,
        modifierGroups: payload.modifierGroups,
      };
    },
    [GET_SYNC_INVENTORY_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        categories: payload.categories,
        modifierGroups: payload.modifierGroups,
      };
    },
    [UPDATE_VARIANT_INFO_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        categories: state.categories.map((category) => {
          return {
            ...category,
            items: category.items.map((item) => {
              return {
                ...item,
                variants: item.variants.map(
                  (variant) => variant._id === payload._id
                    ? payload
                    : variant,
                ),
              };
            }),
          };
        }),
      };
    },
  },
  defaultState,
);
