import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  Grid,
  Header,
  Divider,
  Icon,
} from 'semantic-ui-react';
import { updateModifierInfo } from '../../../sagas/inventory';
import { DASHBOARD_MODIFIER_GROUPS } from '../../../constants/path';
import ModifierForm from '../../../components/modifierForm';

class ModifierDetails extends React.Component {
  componentDidMount() {
    const {
      _id: id,
      name,
      alternateName,
      price,
      preSelect,
    } = this.props.modifier;
    this.props.initForm({
      id,
      name,
      alternateName,
      price,
      preSelect,
    });
  }

  handleSubmit = (form) => {
    return this.props.updateModifier(form);
  }

  render() {
    const { submit, modifier } = this.props;
    if (!modifier) {
      return <div>No Modifier!</div>;
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={{ marginLeft: '10px', marginTop: '1em' }}>
            <Link to={DASHBOARD_MODIFIER_GROUPS} style={{ color: 'black', fontSize: '16px' }}>
              <Icon name="left chevron" />
              Back
            </Link>
          </Grid.Column>
          <Grid.Column width={16} style={{ marginLeft: '10px' }}>
            <Header as="h1" color="blue" textAlign="left" style={{ marginTop: '1em' }}>
              {modifier.name}
            </Header>
            <Divider />
            <ModifierForm submitInfo={submit} modifier={modifier} onSubmit={this.handleSubmit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const { modifierId, modifierGroupId } = ownProps.match.params;
  const modifierGroup = state.inventory.modifierGroups
    .find((modifierGroup) => modifierGroup._id === modifierGroupId) || { modifiers: [] };
  return {
    modifier: modifierGroup.modifiers.find((modifier) => modifier._id === modifierId) || {},
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initForm: (data) => dispatch(initialize('modifier', data)),
    updateModifier: (form) => dispatch(updateModifierInfo(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifierDetails);
