import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import {
  GET_MERCHANT_INFO,
  getMerchantInfoSuccess,
  LIST_MERCHANT_LOCATION,
  listMerchantLocationFailure,
  listMerchantLocationSuccess,
  INSTALL_MERCHANT_LOCATION,
  installMerchantLocationSuccess,
  installMerchantLocationFailure,
  UPDATE_LOCATION_INFO,
  updateLocationInfoSuccess,
  updateLocationInfoFailure,
  updateOwnerInfoSuccess,
  updateOwnerInfoFailure,
  UPDATE_OWNER_INFO,
} from '../reducers/merchant';
import {
  fetchMerchantInfo,
  listMerchantLocation,
  installLocation,
  updateMerchantInfo,
  updateOwnerInfoApi,
  updateLocationInfoApi,
} from '../constants/api/merchant';
import { getInventory } from '../constants/api/inventory';
import { handleErrorRequest } from './errorHandler';

const PREFIX = 'SAGAS/MERCHANT';
export const UPDATE_BUSINESS_INFO = `${PREFIX}/UPDATE_BUSINESS_INFO`;
export const UPDATE_BUSINESS_INFO_SUCCESS = `${PREFIX}/UPDATE_BUSINESS_INFO_SUCCESS`;
export const UPDATE_BUSINESS_INFO_FAILURE = `${PREFIX}/UPDATE_BUSINESS_INFO_FAILURE`;

export const updateBusinessInfo = createAction(UPDATE_BUSINESS_INFO);
export const updateBusinessInfoSuccess = createAction(UPDATE_BUSINESS_INFO_SUCCESS);
export const updateBusinessInfoFailure = createAction(UPDATE_BUSINESS_INFO_FAILURE);


function* getMerchantInfo() {
  try {
    const { data: { data: merchantInfo } } = yield call(fetchMerchantInfo);
    const { _id: merchantId } = merchantInfo.business;
    const { data: { data: inventory } } = yield call(getInventory, merchantId);
    yield put(getMerchantInfoSuccess({ merchantInfo, inventory }));
  } catch (e) {
    yield handleErrorRequest(e);
  }
}

function* getMerchantLocation({ payload }) {
  try {
    const { data } = yield call(listMerchantLocation, payload);
    const { owner, locations, business } = data.data;
    yield put(listMerchantLocationSuccess({ owner, locations, business }));
  } catch (e) {
    yield put(listMerchantLocationFailure());
  }
}

function* installMerchantLocation({ payload }) {
  const { location, merchantId } = payload;
  try {
    yield call(installLocation, location, merchantId);
    yield put(installMerchantLocationSuccess());
  } catch (e) {
    yield put(installMerchantLocationFailure());
  }
}

function* updateBusiness({ payload }) {
  const { _id, ...data } = payload;
  try {
    yield call(updateMerchantInfo, _id, data);
    yield put(updateBusinessInfoSuccess());
  } catch (e) {
    yield put(updateBusinessInfoFailure());
  }
}

function* updateOwner({ payload }) {
  const { _id } = yield select((state) => state.merchant.business);
  try {
    const { data: { data: owner } } = yield call(updateOwnerInfoApi, _id, payload);
    yield put(updateOwnerInfoSuccess(owner));
  } catch (e) {
    yield put(updateOwnerInfoFailure());
  }
}

function* updateLocation({ payload }) {
  const { _id } = yield select((state) => state.merchant.business);
  const { id: locationId, ...data } = payload;
  try {
    const { data: { data: location } } = yield call(updateLocationInfoApi, _id, locationId, data);
    yield put(updateLocationInfoSuccess(location));
  } catch (e) {
    yield put(updateLocationInfoFailure());
  }
}

export function* watchingGetMerchantInfo() {
  yield takeLatest(GET_MERCHANT_INFO, getMerchantInfo);
  yield takeLatest(LIST_MERCHANT_LOCATION, getMerchantLocation);
  yield takeLatest(INSTALL_MERCHANT_LOCATION, installMerchantLocation);
  yield takeLatest(UPDATE_BUSINESS_INFO, updateBusiness);
  yield takeLatest(UPDATE_OWNER_INFO, updateOwner);
  yield takeLatest(UPDATE_LOCATION_INFO, updateLocation);
}
