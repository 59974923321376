import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Header,
  Divider,
  Button,
  Message,
} from 'semantic-ui-react';
import arrayMove from 'array-move';
import SortableList from './sortableContainer';
import { updateCategoriesOrderInfo } from '../../../sagas/inventory';
import FormattedText from '../../../lib/locale/formattedText';

class Category extends React.Component {
  state = {
    items: [],
  }

  componentDidMount() {
    this.setState({ items: this.props.categories });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  handleSave = () => {
    const { items } = this.state;
    const preparedData = items.map((item, index) => ({
      sortCategory: index,
      _id: item._id,
    }));

    return this.props.updateCategoryOrderInfo(preparedData);
  }

  render() {
    const { items } = this.state;
    const { submit } = this.props;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column style={{ marginLeft: '10px' }}>
            <Header as="h1" color="blue" textAlign="left">
              <FormattedText id="categories" defaultMessage="Categories" />
            </Header>
            <div>
              <FormattedText id="page.categories.subtitle" defaultMessage="You can drag and drop the categories to change the order, then click on the save button!" />
            </div>
            <Divider />
            <Button primary onClick={this.handleSave}>
              <FormattedText id="save" defaultMessage="Save" />
            </Button>
            {submit.success && <Message color="green" align="center">{submit.message}</Message>}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SortableList
              items={items}
              onSortEnd={this.onSortEnd}
              pressDelay={200}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    categories: state.inventory.categories,
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCategoryOrderInfo: (data) => dispatch(updateCategoriesOrderInfo(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
