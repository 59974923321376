import React from 'react';
import { Form } from 'semantic-ui-react';
import { daySecondsToStr } from '../../lib/common';

const generateTimeList = (interval) => {
  const timeList = [];
  let seconds = 0;
  while (seconds <= 86400) {
    timeList.push({ key: `time-${seconds}`, text: daySecondsToStr(seconds), value: seconds });
    seconds += interval * 60;
  }
  return timeList;
};

/**
 * Time selector
 * @param {string} label
 * @param {number} interval - in minute
 * @returns {JSX.Element}
 * @constructor
 */
const TimeSelector = ({ label = 'Select time', interval = 15, onChange }) => {
  const timeList = generateTimeList(interval);
  return (
    <Form.Field>
      <Form.Select
        fluid
        label={label}
        options={timeList}
        placeholder="Time"
        onChange={(event, result) => onChange(result.value)}
      />
    </Form.Field>
  );
};

export default TimeSelector;
