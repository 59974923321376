import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  Grid,
  Header,
  Divider,
  Icon,
} from 'semantic-ui-react';
import { updateCategoryInfo } from '../../../sagas/inventory';
import { DASHBOARD_CATEGORIES } from '../../../constants/path';
import CategoryForm from '../../../components/categoryForm';
import FormattedText from '../../../lib/locale/formattedText';

class CategoryDetails extends React.Component {
  componentDidMount() {
    const {
      _id: id,
      name,
      alternateName,
      description,
      warning,
      hidden,
    } = this.props.category;
    this.props.initForm({
      id,
      name,
      alternateName,
      description,
      warning,
      hidden,
    });
  }

  handleSubmit = (form) => {
    return this.props.updateCategory(form);
  }

  render() {
    const { submit, category } = this.props;
    if (!category) {
      return <div>No items yet!</div>;
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={{ marginLeft: '10px', marginTop: '1em' }}>
            <Link to={DASHBOARD_CATEGORIES} style={{ color: 'black', fontSize: '16px' }}>
              <Icon name="left chevron" />
              <FormattedText id="back" defaultMessage="Back" />
            </Link>
          </Grid.Column>
          <Grid.Column width={16} style={{ marginLeft: '10px' }}>
            <Header as="h1" color="blue" textAlign="left" style={{ marginTop: '1em' }}>
              {category.name}
            </Header>
            <Divider />
            <CategoryForm submitInfo={submit} category={category} onSubmit={this.handleSubmit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const { id: categoryId } = ownProps.match.params;
  return {
    category: state.inventory.categories.find((category) => category._id === categoryId) || {},
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initForm: (data) => dispatch(initialize('category', data)),
    updateCategory: (form) => dispatch(updateCategoryInfo(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetails);
