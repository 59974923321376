import React from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { mokaStartInstall } from '../../reducers/install';
import Loading from '../../components/loading';

// TODO: move this out somewhere
const parseQueryUrl = (rawQueryStr) => {
  const queryObj = {};
  rawQueryStr.substring(rawQueryStr.indexOf('?') + 1, rawQueryStr.length)
    .split('&')
    .forEach((query) => {
      if (query) {
        const [key, val] = query.split('=');
        queryObj[key] = val;
      }
    });

  return queryObj;
};

class Install extends React.Component {
  componentDidMount() {
    const queryObj = parseQueryUrl(this.props.location.search);
    if (!queryObj.code) {
      // TODO: redirect or show error????
    }

    // TODO: how to handle page refresh? Refreshing this page would resulting error
    // because the code cannot be used twice

    // TODO: we need to check which POS and do things accordingly
    // for now only MOKA
    this.props.mokaStartInstall(queryObj.code);
  }

  render() {
    return (
      <Container textAlign="center" style={{ paddingTop: '10em' }}>
        {this.props.error ? (
          <div>
            <h1>
              Installation Error. Please contact customer support.
            </h1>
          </div>
        ) : <Loading /> }
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ error: state.install.error });

const mapDispatchToProps = (dispatch) => {
  return {
    mokaStartInstall: (code) => dispatch(mokaStartInstall(code)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Install);
