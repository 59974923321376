import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Form,
  Grid,
  Header,
  Divider,
  Segment,
  Message,
} from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import Input from '../input';
import validate from './validate';
import FormattedText from '../../lib/locale/formattedText';

const OwnerForm = ({ handleSubmit, submitInfo }) => {
  const intl = useIntl();

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450, marginTop: '2em' }}>
        <Form size="large" onSubmit={handleSubmit}>
          <Segment raised>
            <Header as="h2" color="blue">
              <FormattedText id="form.owner-info.title" defaultMessage="Owner Info" />
            </Header>
            <Divider hidden />
            <Field
              component={Input}
              name="firstName"
              placeholder={intl.formatMessage({ id: 'form.owner-info.input-first-name', defaultMessage: 'First Name' })}
            />
            <Field
              component={Input}
              name="lastName"
              placeholder={intl.formatMessage({ id: 'form.owner-info.input-last-name', defaultMessage: 'Last Name' })}
            />
            <Field
              component={Input}
              name="phone"
              placeholder={intl.formatMessage({ id: 'phone', defaultMessage: 'Phone' })}
            />
            {submitInfo.success && <Message color="green">{submitInfo.message}</Message>}
            {!submitInfo.success && submitInfo.message && <Message color="red">{submitInfo.message}</Message>}
            <Button color="blue" fluid size="huge">
              <FormattedText id="save" defaultMessage="Save" />
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default reduxForm({ form: 'owner', validate })(OwnerForm);
