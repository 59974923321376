import React from 'react';
import { reduxForm } from 'redux-form';
import {
  Button,
  Form,
  Grid,
  Divider,
  Message,
  Segment,
} from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import LocationHourList from '../locationHours';
import TimeSelector from '../timeSelector';
import { createLocationHour, deleteLocationHour } from '../../sagas/location';

const dayList = [
  { key: 'sun-0', text: 'Sunday', value: 0 },
  { key: 'mon-1', text: 'Monday', value: 1 },
  { key: 'tue-2', text: 'Tuesday', value: 2 },
  { key: 'wed-3', text: 'Wednesday', value: 3 },
  { key: 'thu-4', text: 'Thursday', value: 4 },
  { key: 'fri-5', text: 'Friday', value: 5 },
  { key: 'sat-6', text: 'Saturday', value: 6 },
];

/**
 * Validating new hour against the existing hour
 * @param {object[]} hours
 * @param {number} day
 * @param {number} startTime
 * @param {number} endTime
 * @returns {boolean}
 */
const validateNewHour = (hours, { day, startTime, endTime }) => {
  if (startTime >= endTime) {
    return false;
  }

  let isValid = true;
  hours
    .filter((h) => h.day === day)
    .forEach((d) => {
      if (startTime === d.startTime || endTime === d.endTime) {
        isValid = false;
      }

      // between start time between existing hours
      if (startTime > d.startTime && startTime < d.endTime) {
        isValid = false;
      }

      // between end time between existing hours
      if (endTime > d.startTime && endTime <= d.endTime) {
        isValid = false;
      }

      if (startTime <= d.startTime && endTime >= d.endTime) {
        isValid = false;
      }
    });
  return isValid;
};

const LocationBusinessHourForm = ({ submitInfo, location }) => {
  const [schedule, setSchedule] = React.useState({ day: null, startTime: null, endTime: null });
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();

  const startTimeChanged = (val) => setSchedule({ ...schedule, startTime: val });
  const endTimeChanged = (val) => setSchedule({ ...schedule, endTime: val });
  const dayChanged = (val) => setSchedule({ ...schedule, day: val });
  const saveSchedule = () => {
    setError(null);
    const isValidHour = validateNewHour(location.locationHours, schedule);
    if (!isValidHour) {
      setError('Selected time is not valid or overlapping the existing hours');
      return;
    }
    dispatch(createLocationHour({ locationId: location._id, hour: schedule }));
  };

  const deleteHour = (hourId) => {
    dispatch(deleteLocationHour({ locationId: location._id, hourId }));
  };

  const intl = useIntl();

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Segment basic>
            <Form onSubmit={saveSchedule}>
              <Form.Field>
                <Form.Select
                  fluid
                  label={intl.formatMessage({ id: 'day', defaultMessage: 'Day' })}
                  options={dayList}
                  placeholder={intl.formatMessage({ id: 'day', defaultMessage: 'Day' })}
                  onChange={(event, result) => dayChanged(result.value)}
                />
              </Form.Field>
              <TimeSelector
                label={intl.formatMessage({ id: 'start-time', defaultMessage: 'Start time' })}
                interval={30}
                onChange={startTimeChanged}
              />
              <TimeSelector
                label={intl.formatMessage({ id: 'end-time', defaultMessage: 'End time' })}
                interval={30}
                onChange={endTimeChanged}
              />
              {submitInfo.success && <Message color="green">{submitInfo.message}</Message>}
              {!submitInfo.success && submitInfo.message && <Message color="red">{submitInfo.message}</Message>}
              <Divider />
              <div>
                <Button
                  fluid
                  color="blue"
                >
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
                {error && <Segment inverted size="small" color="red" style={{ textAlign: 'center' }}>{error}</Segment>}
              </div>
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <LocationHourList
            location={location}
            handleDeleteHour={deleteHour}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default reduxForm({ form: 'location' })(LocationBusinessHourForm);
