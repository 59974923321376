import React, { useEffect } from 'react';
import {
  Grid,
  Header,
  Divider,
  Label,
  Icon,
  Container,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { DASHBOARD_ORDERS } from '../../../constants/path';
import { getOrderDetails, updateOrderState } from '../../../sagas/orders';
import UpdateModal from './updateModal';
import orderStates from './orderStates';
import OrderDetailsView from '../../../components/orderDetailsView';
import { capitalize } from '../../../lib/common';

const OrderDetail = ({
  match, fetchOrderInfo, orderInfo, updateState, submit,
}) => {
  const { id: orderId } = useParams();
  const nextStates = orderStates[orderInfo.state];
  useEffect(() => {
    fetchOrderInfo({ orderId, locationId: orderInfo.location._id });
    /* eslint-disable-next-line */
  }, [fetchOrderInfo]);


  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} style={{ marginLeft: '10px', marginTop: '1em' }}>
          <Link to={DASHBOARD_ORDERS} style={{ color: 'black', fontSize: '16px' }}>
            <Icon name="left chevron" />
            Back
          </Link>
        </Grid.Column>
        <Grid.Column width={16} style={{ marginLeft: '10px' }}>
          <Header as="h1" color="blue" textAlign="left" style={{ marginTop: '1em' }}>
            Order #
            {orderInfo.id || match.params.id}
            <Label color="blue" size="large" style={{ marginLeft: '10px' }}>{capitalize(orderInfo.state)}</Label>
          </Header>
          <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{orderInfo.title}</div>
          <Divider />
          {
            nextStates && (
            <UpdateModal
              nextStates={nextStates}
              updateState={updateState}
              orderId={orderInfo._id}
              locationId={orderInfo.location._id}
            />
            )
          }
          {
            submit.success && (
            <span style={{ marginLeft: '10px', color: '#21ba45' }}>
              <Icon
                as="i"
                color={submit.success ? 'green' : 'red'}
                name={submit.success ? 'checkmark' : 'close'}
              />
              {submit.message}
            </span>
            )
          }
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Container>
          <OrderDetailsView order={orderInfo} />
        </Container>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    orderInfo: state.orders.orderInfo,
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrderInfo: (data) => dispatch(getOrderDetails(data)),
    updateState: (data) => dispatch(updateOrderState(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
