import { handleActions, createAction } from 'redux-actions';

const PREFIX = 'REDUCERS/LOCALE';

export const GET_LOCALE = `${PREFIX}/GET_LOCALE`;
export const GET_LOCALE_SUCCESS = `${PREFIX}/GET_LOCALE_SUCCESS`;
export const GET_LOCALE_FAILURE = `${PREFIX}/GET_LOCALE_FAILURE`;

export const getLocale = createAction(GET_LOCALE);
export const getLocaleSuccess = createAction(GET_LOCALE_SUCCESS);
export const getLocaleFailure = createAction(GET_LOCALE_FAILURE);

export const defaultState = {
  locale: 'en',
  messages: {},
};

export const reducer = handleActions(
  {
    [GET_LOCALE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  defaultState,
);
