import { put, select } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import { getMerchantInfoFailure } from '../reducers/merchant';
import storage from '../lib/storage';

const PREFIX = 'SAGAS/ERROR';
export const REDIRECT_TO_HOME = `${PREFIX}/REDIRECT_TO_HOME`;

export const redirectToHome = createAction(REDIRECT_TO_HOME);

// TODO: should be a global error handler??
export function* handleErrorRequest(e) {
  /* when the token is not valid we check for the
   * location, if /dashboard we redirect to /home
   */
  const { pathname } = yield select((state) => state.router.location);
  if (e.response.status === 401 && pathname === '/dashboard') {
    yield put(getMerchantInfoFailure()); // reset the state to default
    storage.deleteKey('authToken'); // remove the token from local storage
    yield put(redirectToHome());
  }
  // TODO: how about other error status?
}
