import React from 'react';
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { getWidth } from './helpers';
import {
  SIGN_IN,
  SIGN_UP,
} from '../../../constants/path';

export default class MobileNav extends React.Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  showAuthButton = () => {
    const { isLogged, logoutUser } = this.props;
    return !isLogged
      ? (
        <>
          <Menu.Item as={Link} to={SIGN_IN}>Login</Menu.Item>
          <Menu.Item as={Link} to={SIGN_UP}>Sign Up</Menu.Item>
        </>
      )
      : <Menu.Item onClick={logoutUser}>Logout</Menu.Item>;
  }

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="overlay"
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as="a">Pricing</Menu.Item>
          {this.showAuthButton()}
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign="center"
            style={{ padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" color="black" />
                </Menu.Item>
                <Menu.Item>
                  Logo
                  our store
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}
