import http from '../../lib/http';

const API = '/api/v1';

export const fetchOrdersInfo = (query, merchantId) => http.get(`${API}/merchants/${merchantId}/orders?${query}`);
export const fetchOrderDetailsApi = (orderId, locationId) => http.get(`${API}/locations/${locationId}/orders/${orderId}`);
export const putOrderStateApi = (orderId, locationId, state) => http.patch(
  `${API}/locations/${locationId}/orders/${orderId}/state`,
  { data: { state } },
);
