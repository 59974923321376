import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Icon,
  Label,
  Image,
  List,
  Segment,
} from 'semantic-ui-react';
import { SortableElement } from 'react-sortable-hoc';
import { useIntl } from 'react-intl';
import placeholder from '../../../assets/placeholder.svg';
import { DASHBOARD_ITEM_DETAILS } from '../../../constants/path';
import isMobileOrTablet from '../../../lib/mobileDetect';
import style from './item.module.scss';
import FormattedText from '../../../lib/locale/formattedText';

const ItemMenu = (props) => {
  const {
    name,
    hidden,
    image,
    imageAlt,
    _id,
    ItemCategory: {
      categoryId,
    },
  } = props;

  const isMobile = isMobileOrTablet();
  const intl = useIntl();

  return (
    <Segment style={{ display: 'flex', alignItems: 'center', cursor: 'all-scroll' }} raised>
      <Image src={image || imageAlt || placeholder} style={{ maxWidth: '50px', marginRight: '20px' }} />
      <List.Content className={style['item-name']}>{name}</List.Content>
      <div style={{ marginLeft: 'auto' }}>
        {!isMobile && (
        <Label basic color={hidden ? 'red' : 'green'} style={{ marginRight: '30px' }}>
          {hidden
            ? intl.formatMessage({ id: 'hidden', defaultMessage: 'Hidden' })
            : intl.formatMessage({ id: 'visible', defaultMessage: 'Visible' })}
        </Label>
        )}
        <Button as={Link} primary to={DASHBOARD_ITEM_DETAILS(_id, categoryId)} size={isMobile ? 'tiny' : 'small'}>
          <FormattedText id="edit" defaultMessage="Edit" />
          <Icon name="right chevron" />
        </Button>
      </div>
    </Segment>
  );
};

export default SortableElement(ItemMenu);
