import React from 'react';
import { Form } from 'semantic-ui-react';
import style from './input.module.scss';

const Input = (props) => {
  const {
    input,
    icon,
    placeholder,
    type,
    name,
    iconPosition,
    meta: {
      touched,
      error,
    },
  } = props;

  return (
    <>
      <Form.Input
        {...input}
        type={type}
        name={name}
        fluid
        icon={icon}
        iconPosition={iconPosition}
        placeholder={placeholder}
        error={error && touched}
      />
      <div className={style.error}>{touched && error}</div>
    </>
  );
};

Input.defaultProps = ({
  type: 'text',
  iconPosition: null,
  icon: null,
});

export default Input;
