import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { logoutUser } from '../../sagas/auth';
import * as PATH from '../../constants/path';
import Business from './business';
import Owner from './owner';
import Category from './category';
import ModifierGroup from './modifierGroup';
import Item from './item';
import ItemDetails from './itemDetails';
import CategoryDetails from './categoryDetails';
import Orders from './orders';
import OrderDetail from './orderDetails';
import ModifierDetails from './modifierDetails';
import ModifierGroupDetails from './modifierGroupDetails';
import { getMerchantInfo } from '../../reducers/merchant';
import DashboardLayout from '../../components/dashboardLayout';
import Locations from './locations';
import locationDetails from './locationDetails';

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.getMerchantInfo();
  }

  render() {
    const { isLoading, authLogout } = this.props;
    if (isLoading) {
      return null;
    }

    return (
      <DashboardLayout logoutUser={authLogout}>
        <Switch>
          <Route path={PATH.DASHBOARD_BUSINESS} component={Business} />
          <Route path={PATH.DASHBOARD_OWNER} component={Owner} />
          <Route exact path={PATH.DASHBOARD_LOCATIONS} component={Locations} />
          <Route exact path={PATH.DASHBOARD_ITEMS} component={Item} />
          <Route exact path={PATH.DASHBOARD_CATEGORIES} component={Category} />
          <Route exact path={`${PATH.DASHBOARD_CATEGORIES}/:categoryId/items/:itemId`} component={ItemDetails} />
          <Route exact path={`${PATH.DASHBOARD_MODIFIER_GROUPS}/:modifierGroupId/modifiers/:modifierId`} component={ModifierDetails} />
          <Route exact path={`${PATH.DASHBOARD_MODIFIER_GROUPS}/:modifierGroupId`} component={ModifierGroupDetails} />
          <Route path={`${PATH.DASHBOARD_CATEGORIES}/:id`} component={CategoryDetails} />
          <Route path={PATH.DASHBOARD_MODIFIER_GROUPS} component={ModifierGroup} />
          <Route exact path={PATH.DASHBOARD_ORDERS} component={Orders} />
          <Route exact path={`${PATH.DASHBOARD_ORDERS}/:id`} component={OrderDetail} />
          <Route path={`${PATH.DASHBOARD_LOCATIONS}/:id`} component={locationDetails} />
          <Redirect exact from={PATH.DASHBOARD} to={PATH.DASHBOARD_BUSINESS} />
        </Switch>
      </DashboardLayout>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    isLoading: state.ui.isLoading,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    authLogout: () => dispatch(logoutUser()),
    getMerchantInfo: () => dispatch(getMerchantInfo()),
  };
};


export default connect(mapStatetoProps, mapDispatchToProps)(Dashboard);
