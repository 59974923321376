const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Modifier name is required';
  }

  return errors;
};

export default validate;
