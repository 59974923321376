import { handleActions, combineActions } from 'redux-actions';
import {
  GET_ORDERS_SUCCESS,
  GET_ORDER_DETAILS_SUCCESS,
  UPDATE_ORDER_STATE_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
} from '../sagas/orders';

export const defaultState = {
  count: 0,
  rows: [],
  orderInfo: {
    error: false,
    loading: true,
    customer: {},
    orderItems: [],
    location: {},
  },
};

export const reducer = handleActions(
  {
    [GET_ORDERS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [combineActions(
      GET_ORDER_DETAILS_SUCCESS,
      UPDATE_ORDER_STATE_SUCCESS,
    )]: (state, { payload }) => {
      return {
        ...state,
        orderInfo: { ...state.orderInfo, ...payload, loading: false },
      };
    },
    [GET_ORDER_DETAILS_FAILURE]: (state) => {
      return {
        ...state,
        orderInfo: { ...state.orderInfo, error: true, loading: false },
      };
    },
  },
  defaultState,
);
