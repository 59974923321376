import React from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Footer from './footer';
import ResponsiveNav from './responsiveNav';
import { getMerchantInfo } from '../../reducers/merchant';
import { logoutUser } from '../../sagas/auth';

class BasicLayout extends React.Component {
  componentDidMount() {
    this.props.getMerchantInfo();
  }

  render() {
    const {
      children,
      isLogged,
      fluid,
      style,
      textAlign,
      authLogout,
      withNav = true,
      withFooter = true,
    } = this.props;
    return (
      <div>
        {withNav ? <ResponsiveNav isLogged={isLogged} logoutUser={authLogout} /> : null}
        <Container
          style={{ paddingTop: '70px', flex: 1, ...style }}
          fluid={fluid}
          textAlign={textAlign}
        >
          {children}
        </Container>
        {withFooter ? <Footer /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.merchant.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMerchantInfo: () => dispatch(getMerchantInfo()),
    authLogout: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicLayout);
