import React, { useState } from 'react';
import {
  Button,
  Modal,
  Dropdown,
} from 'semantic-ui-react';

const UpdateModal = ({
  nextStates, updateState, orderId, locationId,
}) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(null);
  const formattedStates = nextStates.map((state) => ({
    key: state, text: state, value: state,
  }));

  const handleConfirm = () => {
    updateState({ state, orderId, locationId });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      onClose={handleClose}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button color="green">Update</Button>}
      size="tiny"
    >
      <Modal.Header>Order state</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <p>
            Select the new state:
          </p>
          <Dropdown
            options={formattedStates}
            placeholder="Choose a state"
            selection
            onChange={(e, data) => setState(data.value)}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" onClick={handleClose}>
          Close
        </Button>
        <Button
          content="Confirm"
          labelPosition="right"
          icon="checkmark"
          onClick={handleConfirm}
          positive
          disabled={!state}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default UpdateModal;
