import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import BusinessForm from '../../../components/businessForm';
import { updateBusinessInfo } from '../../../sagas/merchant';

class Business extends React.Component {
  componentDidMount() {
    const { initForm, business } = this.props;
    initForm(business);
  }

  handleSubmit = (form) => {
    this.props.updateBusinessInfo(form);
  }

  render() {
    return <BusinessForm onSubmit={this.handleSubmit} submitInfo={this.props.submit} />;
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.merchant.business,
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initForm: (data) => dispatch(initialize('business', data)),
    updateBusinessInfo: (form) => dispatch(updateBusinessInfo(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Business);
