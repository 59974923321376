const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Business name is required';
  }

  if (!values.phone) {
    errors.phone = 'Business phone is required';
  }

  if (!values.address1) {
    errors.address1 = 'Address1 is required';
  }

  if (!values.state) {
    errors.state = 'State is required';
  }

  if (!values.zip) {
    errors.zip = 'Zip is required';
  }

  return errors;
};

export default validate;
