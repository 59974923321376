import { Card } from 'semantic-ui-react';
import React from 'react';
import ButtonInstallLocation from '../buttonInstallLocation';
import ButtonOpenDashboard from '../buttonOpenDashboard';
import ButtonUninstallLocation from '../buttonUninstallLocation';

const renderButton = (location, installLocation, openMerchantDashboard) => {
  const { installed } = location;
  const install = () => installLocation(location);
  if (installed) {
    return (
      <div className="ui two buttons">
        <ButtonUninstallLocation />
        {' '}
        <ButtonOpenDashboard handleClick={openMerchantDashboard} />
      </div>
    );
  }
  return <ButtonInstallLocation handleClick={install} />;
};

const CardLocation = ({ location, installLocation, openMerchantDashboard }) => {
  return (
    <Card centered>
      <Card.Content>
        <Card.Header>{location.name}</Card.Header>
        <Card.Meta><strong>{location.phone}</strong></Card.Meta>
        <Card.Description>
          <div>{location.address1}</div>
          <div>{location.city}</div>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui three buttons">
          { renderButton(location, installLocation, openMerchantDashboard)}
        </div>
      </Card.Content>
    </Card>
  );
};

CardLocation.defaultProps = ({
  location: {
    name: null,
    phone: null,
    address1: null,
    city: null,
    installed: false,
  },
});

export default CardLocation;
