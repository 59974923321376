import { createAction } from 'redux-actions';
import { call, select, takeLatest } from 'redux-saga/effects';
import { put } from '@redux-saga/core/effects';
import { addLocationHourApi, deleteLocationHourApi, setNotificationSettingsApi } from '../constants/api/location';

const PREFIX = 'SAGAS/LOCATION';
export const CREATE_LOCATION_HOUR = `${PREFIX}/CREATE_LOCATION_HOUR`;
export const CREATE_LOCATION_HOUR_SUCCESS = `${PREFIX}/CREATE_LOCATION_HOUR_SUCCESS`;
export const CREATE_LOCATION_HOUR_FAILURE = `${PREFIX}/CREATE_LOCATION_HOUR_FAILURE`;

export const createLocationHour = createAction(CREATE_LOCATION_HOUR);
export const createLocationHourSuccess = createAction(CREATE_LOCATION_HOUR_SUCCESS);
export const createLocationHourFailure = createAction(CREATE_LOCATION_HOUR_FAILURE);

export const DELETE_LOCATION_HOUR = `${PREFIX}/DELETE_LOCATION_HOUR`;
export const DELETE_LOCATION_HOUR_SUCCESS = `${PREFIX}/DELETE_LOCATION_HOUR_SUCCESS`;
export const DELETE_LOCATION_HOUR_FAILURE = `${PREFIX}/DELETE_LOCATION_HOUR_FAILURE`;

export const deleteLocationHour = createAction(DELETE_LOCATION_HOUR);
export const deleteLocationHourSuccess = createAction(DELETE_LOCATION_HOUR_SUCCESS);
export const deleteLocationHourFailure = createAction(DELETE_LOCATION_HOUR_FAILURE);

export const SELECT_LOCATION = `${PREFIX}/SELECT_LOCATION`;
export const selectLocation = createAction(SELECT_LOCATION);

export const SET_NOTIFICATION_SETTINGS = `${PREFIX}/SET_NOTIFICATION_SETTINGS`;
export const SET_NOTIFICATION_SETTINGS_SUCCESS = `${PREFIX}/SET_NOTIFICATION_SETTINGS_SUCCESS`;
export const SET_NOTIFICATION_SETTINGS_FAILURE = `${PREFIX}/SET_NOTIFICATION_SETTINGS_FAILURE`;

export const setNotificationSettings = createAction(SET_NOTIFICATION_SETTINGS);
export const setNotificationSettingsSuccess = createAction(SET_NOTIFICATION_SETTINGS_SUCCESS);
export const setNotificationSettingsFailure = createAction(SET_NOTIFICATION_SETTINGS_FAILURE);

function* createHour({ payload }) {
  const { locationId, hour } = payload;
  const { _id: merchantId } = yield select((state) => state.merchant.business);
  try {
    const { data: { data: hours } } = yield call(
      addLocationHourApi, { merchantId, locationId, hour },
    );
    yield put(createLocationHourSuccess({ locationHours: hours }));
  } catch (err) {
    yield put(createLocationHourFailure());
  }
}

function* deleteHour({ payload }) {
  const { locationId, hourId } = payload;
  const { _id: merchantId } = yield select((state) => state.merchant.business);
  try {
    yield call(deleteLocationHourApi, { merchantId, locationId, hourId });
    const { locationHours } = yield select((state) => state.location);
    yield put(deleteLocationHourSuccess({
      locationHours: locationHours.filter((hour) => hour._id !== hourId),
    }));
  } catch (err) {
    yield put(deleteLocationHourFailure());
  }
}

function* setNotification({ payload }) {
  try {
    const { locationId, email, phone } = payload;
    const { _id: merchantId } = yield select((state) => state.merchant.business);
    yield call(setNotificationSettingsApi, {
      locationId, merchantId, email, phone,
    });
  } catch (err) {
    yield put(setNotificationSettingsFailure());
  }
}

export function* watchLocation() {
  yield takeLatest(CREATE_LOCATION_HOUR, createHour);
  yield takeLatest(DELETE_LOCATION_HOUR, deleteHour);
  yield takeLatest(SET_NOTIFICATION_SETTINGS, setNotification);
}
