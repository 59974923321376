import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid,
  Header,
  Divider,
  Segment,
  Button,
  Message,
  Icon,
  List,
} from 'semantic-ui-react';
import arrayMove from 'array-move';
import SortableList from './sortableContainer';
import { updateModifiersOrderInfo } from '../../../sagas/inventory';
import { DASHBOARD_MODIFIER_GROUP_DETAILS } from '../../../constants/path';
import FormattedText from '../../../lib/locale/formattedText';

class ModifierGroup extends React.Component {
  state = {
    collections: [],
  }

  componentDidMount() {
    const { modifierGroups } = this.props;
    this.setState({ collections: modifierGroups.map((modifierGroup) => modifierGroup.modifiers) });
  }

  onSortEnd = ({ oldIndex, newIndex, collection }) => {
    this.setState(({ collections }) => {
      const newCollections = [...collections];

      newCollections[collection] = arrayMove(
        collections[collection],
        oldIndex,
        newIndex,
      );

      return { collections: newCollections };
    });
  };

  handleSave = () => {
    const { collections } = this.state;
    const preparedData = collections.reduce((acc, items) => {
      const preparedItems = items.map((item, index) => ({
        sortOrder: index,
        modifierId: item._id,
      }));
      return acc.concat(preparedItems);
    }, []);

    return this.props.updateModifiersOrderInfo(preparedData);
  }

  render() {
    const { collections } = this.state;
    const { modifierGroups, submit } = this.props;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={{ marginLeft: '10px' }}>
            <Header as="h1" color="blue" textAlign="left">
              <FormattedText id="modifier-groups" defaultMessage="Modifier Groups" />
            </Header>
            <div>
              <FormattedText
                id="page.modifier-groups.subtitle"
                defaultMessage="You can drag and drop the modifiers to change the order,
              then click on the save button!"
              />
            </div>
            <Divider />
            <Button primary onClick={this.handleSave}>
              <FormattedText id="save" defaultMessage="Save" />
            </Button>
            {submit.success && <Message color="green" align="center">{submit.message}</Message>}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {
              collections.map((items, index) => {
                return (
                  <Segment.Group raised key={index}>
                    <Segment as="h5" size="big" secondary={items.length === 0} style={{ display: 'flex', alignItems: 'center' }}>
                      <List.Content>{modifierGroups[index].name}</List.Content>
                      <div style={{ marginLeft: 'auto' }}>
                        <Button
                          as={Link}
                          primary
                          to={DASHBOARD_MODIFIER_GROUP_DETAILS(modifierGroups[index]._id)}
                          inverted
                        >
                          <FormattedText id="edit" defaultMessage="Edit" />
                          <Icon name="right chevron" />
                        </Button>
                      </div>
                    </Segment>
                    {
                      items.length > 0
                      && (
                      <SortableList
                        items={items}
                        onSortEnd={this.onSortEnd}
                        pressDelay={200}
                        collection={index}
                      />
                      )
                    }
                  </Segment.Group>
                );
              })
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    modifierGroups: state.inventory.modifierGroups,
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateModifiersOrderInfo: (data) => dispatch(updateModifiersOrderInfo(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifierGroup);
