import { Button } from 'semantic-ui-react';
import React from 'react';

const ButtonOpenDashboard = ({ handleClick }) => {
  return (<Button basic color="blue" onClick={handleClick}>Dashboard</Button>);
};

ButtonOpenDashboard.defaultProps = ({
  businessId: null,
  locationId: null,
});

export default ButtonOpenDashboard;
