import React from 'react';
import { daySecondsToStr } from '../../lib/common';

// TODO: accept different format, currently the time prop only accepts seconds of the day
const TimeFormat = ({ time }) => {
  return (
    <span>
      {daySecondsToStr(time)}
    </span>
  );
};

export default TimeFormat;
