import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

const mapStateToProps = (state) => {
  return {
    locale: state.locale.locale,
    messages: state.locale.messages,
  };
};

export default connect(mapStateToProps)(IntlProvider);
