const getKey = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

const setKey = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const deleteKey = (key) => {
  window.localStorage.removeItem(key);
};

export default {
  getKey,
  setKey,
  deleteKey,
};
