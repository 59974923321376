import React, { useState, useEffect } from 'react';
import {
  Grid, Menu, Image, Container,
} from 'semantic-ui-react';
import VariantForm from '../../../components/variantForm';
import warningIcon from '../../../assets/warning-icon.svg';
import FormattedText from '../../../lib/locale/formattedText';

const Variants = ({
  variants, submitInfo, onSubmit, init,
}) => {
  const [activeItem, setActiveItem] = useState(variants[0]);

  useEffect(() => {
    if (!activeItem) {
      return;
    }

    const {
      _id: id,
      name,
      price,
      sku,
      sortOrder,
    } = activeItem;
    init({
      id,
      name,
      price,
      sku,
      sortOrder,
    });
  }, [activeItem, init]);

  if (variants.length === 0) {
    return (
      <Container textAlign="center" style={{ paddingTop: '5%' }}>
        <Image src={warningIcon} size="small" centered />
        <div style={{ fontSize: '18px', marginTop: '20px', fontWeight: '500' }}>
          <FormattedText id="variants.warning" defaultMessage="The current Item does not have variants." />
        </div>
      </Container>

    );
  }

  return (
    <Grid style={{ marginTop: '1em' }}>
      <Grid.Column width={4}>
        <Menu fluid vertical tabular>
          {
            variants.map((variant, i) => {
              return (
                <Menu.Item
                  key={i}
                  name={variant.name}
                  active={activeItem.name === variant.name}
                  onClick={() => setActiveItem(variant)}
                />
              );
            })
          }
        </Menu>
      </Grid.Column>

      <Grid.Column stretched width={12}>

        <VariantForm submitInfo={submitInfo} variant={activeItem} onSubmit={onSubmit} />

      </Grid.Column>
    </Grid>
  );
};

export default Variants;
