import React from 'react';
import {
  Grid,
  Header,
  Icon,
} from 'semantic-ui-react';

const Error = ({ message }) => {
  return (
    <Grid divided="vertically" centered>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Icon size="huge" name="frown outline" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1" textAlign="center">
            {message}
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Error;
