import React from 'react';
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { getWidth } from './helpers';
import MenuItems from './menuItems';
import logo from '../../../assets/logo.svg';

class MobileNav extends React.Component {
  state = { sidebarOpened: false }

  handleItemClick = (e, { route }) => {
    this.handleSidebarHide();
    this.props.history.push(route);
  }

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children, pathname, logoutUser } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="overlay"
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
          inverted
        >
          <MenuItems
            handleItemClick={this.handleItemClick}
            pathname={pathname}
            logoutUser={logoutUser}
          />
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            textAlign="center"
            style={{ padding: '1em 0em' }}
            vertical
            animation="overlay"
          >
            <Container>
              <Menu secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item>
                  <img src={logo} style={{ width: '150px', padding: '15px', margin: 'auto' }} alt="logo" />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

export default withRouter(MobileNav);
