import React from 'react';
import {
  Grid,
  Header,
  Divider,
  Container,
  Icon,
} from 'semantic-ui-react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import LocationsTable from './locationsTable';
import { getSyncInventory } from '../../../sagas/inventory';
import Loader from '../../../components/loading';
import FormattedText from '../../../lib/locale/formattedText';

const Locations = ({
  push, locations, syncInventory, submit, showLoader,
}) => {
  const handleSync = (locationId) => {
    syncInventory({ locationId });
  };

  return (
    <Grid>
      {showLoader && <Loader text="Synchronizing..." inverted={false} />}
      <Grid.Row>
        <Grid.Column width={16} style={{ marginLeft: '10px' }}>
          <Header as="h1" color="blue" textAlign="left">
            <FormattedText id="locations" defaultMessage="Locations" />
          </Header>
          <Divider />
          {
            !showLoader && submit.success !== null && (
            <span style={{ marginLeft: '10px', color: submit.success ? '#21ba45' : 'red' }}>
              <Icon
                as="i"
                color={submit.success ? 'green' : 'red'}
                name={submit.success ? 'checkmark' : 'close'}
              />
              {submit.message}
            </span>
            )
          }
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Container>
          <LocationsTable
            locations={locations}
            redirect={push}
            size="large"
            handleSync={handleSync}
          />
        </Container>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    locations: state.merchant.locations,
    submit: state.ui.submit,
    showLoader: state.ui.showLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (url) => dispatch(push(url)),
    syncInventory: (data) => dispatch(getSyncInventory(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
