import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Form,
  Grid,
  Header,
  Divider,
  Message,
  Segment,
} from 'semantic-ui-react';
import Input from '../input';
// import validate from './validate';

class VariantForm extends React.Component {
  render() {
    const { handleSubmit, variant, submitInfo } = this.props;
    return (
      <Grid textAlign="center" verticalAlign="middle">
        <Grid.Column style={{ maxWidth: '450px', marginTop: '1em' }}>
          <Segment raised>
            <Header as="h3" color="blue" style={{ textTransform: 'uppercase' }}>{variant.name}</Header>
            <Form size="large" onSubmit={handleSubmit}>
              <Divider hidden />
              <Field
                component={Input}
                name="name"
                placeholder="Name"
              />
              <Field
                component={Input}
                name="price"
                placeholder="Price"
              />
              <Field
                component={Input}
                name="sku"
                placeholder="SKU"
              />
              <Field
                component={Input}
                type="number"
                name="sortOrder"
                placeholder="Order"
              />
              {submitInfo.success && <Message color="green">{submitInfo.message}</Message>}
              {!submitInfo.success && submitInfo.message && <Message color="red">{submitInfo.message}</Message>}
              <Button color="blue" fluid size="huge" type="submit">
                Save changes
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default reduxForm({ form: 'variant' })(VariantForm);
