import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'REDUCERS/MERCHANT';
export const GET_MERCHANT_INFO = `${PREFIX}/GET_MERCHANT_INFO`;
export const GET_MERCHANT_INFO_SUCCESS = `${PREFIX}/GET_MERCHANT_INFO_SUCCESS`;
export const GET_MERCHANT_INFO_FAILURE = `${PREFIX}/GET_MERCHANT_INFO_FAILURE`;

export const LIST_MERCHANT_LOCATION = `${PREFIX}/LIST_MERCHANT_LOCATION`;
export const LIST_MERCHANT_LOCATION_SUCCESS = `${PREFIX}/LIST_MERCHANT_LOCATION_SUCCESS`;
export const LIST_MERCHANT_LOCATION_FAILURE = `${PREFIX}/LIST_MERCHANT_LOCATION_FAILURE`;

export const INSTALL_MERCHANT_LOCATION = `${PREFIX}/INSTALL_MERCHANT_LOCATION`;
export const INSTALL_MERCHANT_LOCATION_SUCCESS = `${PREFIX}/INSTALL_MERCHANT_LOCATION_SUCCESS`;
export const INSTALL_MERCHANT_LOCATION_FAILURE = `${PREFIX}/INSTALL_MERCHANT_LOCATION_FAILURE`;

export const OPEN_DASHBOARD = `${PREFIX}/OPEN_DASHBOARD`;
export const openDashboard = createAction(OPEN_DASHBOARD);

export const getMerchantInfo = createAction(GET_MERCHANT_INFO);
export const getMerchantInfoSuccess = createAction(GET_MERCHANT_INFO_SUCCESS);
export const getMerchantInfoFailure = createAction(GET_MERCHANT_INFO_FAILURE);

export const listMerchantLocation = createAction(LIST_MERCHANT_LOCATION);
export const listMerchantLocationSuccess = createAction(LIST_MERCHANT_LOCATION_SUCCESS);
export const listMerchantLocationFailure = createAction(LIST_MERCHANT_LOCATION_FAILURE);

export const installMerchantLocation = createAction(INSTALL_MERCHANT_LOCATION);
export const installMerchantLocationSuccess = createAction(INSTALL_MERCHANT_LOCATION_SUCCESS);
export const installMerchantLocationFailure = createAction(INSTALL_MERCHANT_LOCATION_FAILURE);

export const UPDATE_OWNER_INFO = `${PREFIX}/UPDATE_OWNER_INFO`;
export const UPDATE_OWNER_INFO_SUCCESS = `${PREFIX}/UPDATE_OWNER_INFO_SUCCESS`;
export const UPDATE_OWNER_INFO_FAILURE = `${PREFIX}/UPDATE_OWNER_INFO_FAILURE`;

export const updateOwnerInfo = createAction(UPDATE_OWNER_INFO);
export const updateOwnerInfoSuccess = createAction(UPDATE_OWNER_INFO_SUCCESS);
export const updateOwnerInfoFailure = createAction(UPDATE_OWNER_INFO_FAILURE);

export const UPDATE_LOCATION_INFO = `${PREFIX}/UPDATE_LOCATION_INFO`;
export const UPDATE_LOCATION_INFO_SUCCESS = `${PREFIX}/UPDATE_LOCATION_INFO_SUCCESS`;
export const UPDATE_LOCATION_INFO_FAILURE = `${PREFIX}/UPDATE_LOCATION_INFO_FAILURE`;

export const updateLocationInfo = createAction(UPDATE_LOCATION_INFO);
export const updateLocationInfoSuccess = createAction(UPDATE_LOCATION_INFO_SUCCESS);
export const updateLocationInfoFailure = createAction(UPDATE_LOCATION_INFO_FAILURE);

export const defaultState = {
  isLogged: false,
  owner: {
    firstName: '',
    lastName: '',
    phone: '',
  },
  locations: [{}],
  business: {},
  error: false,
};

export const reducer = handleActions(
  {
    [GET_MERCHANT_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      owner: payload.merchantInfo.owner,
      business: payload.merchantInfo.business,
      categories: payload.merchantInfo.categories,
      locations: payload.merchantInfo.locations,
      isLogged: true,
    }),
    [GET_MERCHANT_INFO_FAILURE]: () => ({
      ...defaultState,
    }),
    [LIST_MERCHANT_LOCATION_SUCCESS]: (state, { payload: { owner, locations, business } }) => {
      return {
        ...state,
        owner,
        locations,
        business,
      };
    },
    [LIST_MERCHANT_LOCATION_FAILURE]: (state) => {
      // TODO: what to do here? how the error should be displayed?
      return {
        ...state,
        error: true,
      };
    },
    [INSTALL_MERCHANT_LOCATION_FAILURE]: (state) => {
      // TODO: what to do here? how the error should be displayed?
      return {
        ...state,
        error: true,
      };
    },
    [UPDATE_LOCATION_INFO_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        locations: state.locations.map(
          (location) => location._id === payload._id ? payload : location,
        ),
      };
    },
    [UPDATE_OWNER_INFO_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        owner: payload,
      };
    },
  },
  defaultState,
);
