import {
  Responsive,
} from 'semantic-ui-react';

const getWidth = () => {
  const isSSR = typeof window === 'undefined';

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const isActive = (itemPath, pathname) => `${itemPath}` === pathname;
export { getWidth, isActive };
