import React, { useEffect } from 'react';
import ItemForm from '../../../components/itemForm';

const Item = ({
  item, submitInfo, onSubmit, init,
}) => {
  useEffect(() => {
    const {
      _id: id,
      name,
      alternateName,
      description,
      warning,
      hidden,
      sku,
      label,
    } = item;
    init({
      id,
      name,
      alternateName,
      description,
      warning,
      hidden,
      sku,
      label,
    });
  }, [item, init]);

  return <ItemForm submitInfo={submitInfo} item={item} onSubmit={onSubmit} />;
};

export default Item;
