import { Button } from 'semantic-ui-react';
import React from 'react';

const ButtonInstallLocation = ({ handleClick }) => {
  return (<Button basic color="blue" onClick={handleClick}>Install</Button>);
};

ButtonInstallLocation.defaultProps = ({
  businessId: null,
  locationId: null,
});

export default ButtonInstallLocation;
