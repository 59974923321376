import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  Grid,
  Header,
  Divider,
  Icon,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { updateModifierGroupInfo } from '../../../sagas/inventory';
import { DASHBOARD_MODIFIER_GROUPS } from '../../../constants/path';
import ModifierGroupForm from '../../../components/modifierGroupForm';
import FormattedText from '../../../lib/locale/formattedText';

class ModifierGroupDetails extends React.Component {
  componentDidMount() {
    const {
      _id: id,
      name,
      alternateName,
      description,
      warning,
      minimum,
      maximum,
      hidden,
    } = this.props.modifierGroup;
    this.props.initForm({
      id,
      name,
      alternateName,
      description,
      warning,
      minimum,
      maximum,
      hidden,
    });
  }

  handleSubmit = (form) => {
    return this.props.updateModifierGroup(form);
  };

  render() {
    const { submit, modifierGroup } = this.props;
    if (!modifierGroup) {
      return <div>No Modifier!</div>;
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={{ marginLeft: '10px', marginTop: '1em' }}>
            <Link to={DASHBOARD_MODIFIER_GROUPS} style={{ color: 'black', fontSize: '16px' }}>
              <Icon name="left chevron" />
              <FormattedText id="back" defaultMessage="Back" />
            </Link>
          </Grid.Column>
          <Grid.Column width={16} style={{ marginLeft: '10px' }}>
            <Header as="h1" color="blue" textAlign="left" style={{ marginTop: '1em' }}>
              {modifierGroup.name}
            </Header>
            <Divider />
            <ModifierGroupForm
              submitInfo={submit}
              modifierGroup={modifierGroup}
              onSubmit={this.handleSubmit}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const { modifierGroupId } = ownProps.match.params;
  const modifierGroup = state.inventory.modifierGroups
    .find((modifierGroup) => modifierGroup._id === modifierGroupId) || {};
  return {
    modifierGroup,
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initForm: (data) => dispatch(initialize('modifierGroup', data)),
    updateModifierGroup: (form) => dispatch(updateModifierGroupInfo(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModifierGroupDetails));
