import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  LIST_MERCHANT_LOCATION_FAILURE,
  INSTALL_MERCHANT_LOCATION_FAILURE,
} from './merchant';

export const defaultState = {
  isError: false,
  message: null,
};

export const reducer = handleActions(
  {
    [LOCATION_CHANGE]: () => {
      return defaultState;
    },
    [LIST_MERCHANT_LOCATION_FAILURE]: () => {
      return {
        isError: true,
        message: 'Sorry, there is an error on listing your business location. Please contact xxx-xxx-xxxxx',
      };
    },
    [INSTALL_MERCHANT_LOCATION_FAILURE]: () => {
      return {
        isError: true,
        message: 'Sorry, there is an error while installing your business location. Please contact xxx-xxx-xxxxx',
      };
    },
  },
  defaultState,
);
