import { createAction } from 'redux-actions';
import {
  call,
  takeLatest,
  put,
} from 'redux-saga/effects';
import { authLogoutUser } from '../constants/api/auth';
import storage from '../lib/storage';

const PREFIX = 'SAGAS/AUTH';
export const LOGOUT_USER = `${PREFIX}/LOGOUT_USER`;
export const LOGOUT_USER_SUCCESS = `${PREFIX}/LOGOUT_USER_SUCCESS`;
export const STORE_TOKEN = `${PREFIX}/STORE_TOKEN`;

export const logoutUser = createAction(LOGOUT_USER);
export const logoutUserSuccess = createAction(LOGOUT_USER_SUCCESS);
export const storeAuthToken = createAction(STORE_TOKEN);

function* authLogout() {
  try {
    yield call(authLogoutUser);
    storage.deleteKey('authToken');
    yield put(logoutUserSuccess());
  } catch (e) {
    // TODO do something when it fails?
    // how about just redirect the user to home?
    console.error(e);
  }
}

function* storeToken({ payload }) {
  yield storage.setKey('authToken', payload);
}

export function* watchingStoreToken() {
  yield takeLatest(STORE_TOKEN, storeToken);
}

export function* watchingAuthLogout() {
  yield takeLatest(LOGOUT_USER, authLogout);
}
