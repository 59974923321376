import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { reducer as merchant } from './merchant';
import { reducer as install } from './install';
import { reducer as error } from './error';
import { reducer as ui } from './ui';
import { reducer as inventory } from './inventory';
import { reducer as orders } from './orders';
import { reducer as location } from './location';
import history from '../store/history';
import { reducer as locale } from '../lib/locale/reducer';

export default combineReducers({
  merchant,
  orders,
  inventory,
  install,
  router: connectRouter(history),
  form: formReducer,
  error,
  ui,
  locale,
  location,
});
