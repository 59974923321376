import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import OwnerForm from '../../../components/ownerForm';
import { updateOwnerInfo } from '../../../reducers/merchant';

class Owner extends React.Component {
  componentDidMount() {
    const { initForm, owner } = this.props;
    initForm(owner);
  }

  handleSubmit = (form) => {
    this.props.updateOwnerInfo(form);
  }

  render() {
    return <OwnerForm onSubmit={this.handleSubmit} submitInfo={this.props.submit} />;
  }
}

const mapStateToProps = (state) => {
  return {
    owner: state.merchant.owner,
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initForm: (data) => dispatch(initialize('owner', data)),
    updateOwnerInfo: (form) => dispatch(updateOwnerInfo(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Owner);
