import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Form, Grid, Header,
} from 'semantic-ui-react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setNotificationSettings } from '../../sagas/location';

const NotificationForm = ({ location }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const dispatch = useDispatch();
  const saveNotification = () => {
    dispatch(setNotificationSettings({ locationId: location._id, email, phone }));
  };

  useEffect(() => {
    location.locationNotifications.forEach(({ receiver, receiverType }) => {
      return receiverType === 'email' ? setEmail(receiver) : setPhone(receiver);
    });
  }, []);
  return (
    <>
      <Header size="small" color="blue">Set your phone number and email to receive order notification</Header>
      <Form onSubmit={saveNotification}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field>
                <Form.Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  name="phoneNotification"
                  label="Phone (including country code)"
                  type="tel"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <Form.Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="emailNotification"
                  label="Email"
                  type="email"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <div>
          <Button color="blue" fluid>
            <FormattedMessage id="save" defaultMessage="Save" />
          </Button>
        </div>
      </Form>
    </>
  );
};

export default reduxForm({ form: 'notification' })(NotificationForm);
