import { createAction, handleActions } from 'redux-actions';
import storage from '../lib/storage';

const PREFIX = 'REDUCERS/MOKA';
export const MOKA_START_INSTALL = `${PREFIX}/MOKA_START_INSTALL`;
export const MOKA_START_INSTALL_SUCCESS = `${PREFIX}/MOKA_START_INSTALL_SUCCESS`;
export const MOKA_START_INSTALL_FAILURE = `${PREFIX}/MOKA_START_INSTALL_FAILURE`;

export const mokaStartInstall = createAction(MOKA_START_INSTALL);
export const mokaStartInstallSuccess = createAction(MOKA_START_INSTALL_SUCCESS);
export const mokaStartInstallFailure = createAction(MOKA_START_INSTALL_FAILURE);

export const defaultState = {
  businessId: '',
  error: false,
};

export const reducer = handleActions(
  {
    [MOKA_START_INSTALL_SUCCESS]: (state, { payload: { businessId, token } }) => {
      // TODO: move this out somewhere because we have another installation method
      storage.setKey('authToken', token);
      return {
        ...state,
        businessId,
      };
    },
    [MOKA_START_INSTALL_FAILURE]: () => {
      return ({
        ...defaultState,
        error: true,
      });
    },
  },
  defaultState,
);
