import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import {
  updateCategoryInfoApi,
  updateItemInfoApi,
  updateItemsOrderInfoApi,
  updateCategoriesOrderInfoApi,
  updateModifiersOrderInfoApi,
  updateModifierInfoApi,
  updateModifierGroupInfoApi,
  getSyncInventoryAPI,
  getSyncItemsAPI,
  getInventory,
  getSyncModifierGroupsAPI,
  updateVariantInfoApi,
} from '../constants/api/inventory';

const PREFIX = 'SAGAS/INVENTORY';
export const UPDATE_CATEGORY_INFO = `${PREFIX}/UPDATE_CATEGORY_INFO`;
export const UPDATE_CATEGORY_INFO_SUCCESS = `${PREFIX}/UPDATE_CATEGORY_INFO_SUCCESS`;
export const UPDATE_CATEGORY_INFO_FAILURE = `${PREFIX}/UPDATE_CATEGORY_INFO_FAILURE`;

export const updateCategoryInfo = createAction(UPDATE_CATEGORY_INFO);
export const updateCategoryInfoSuccess = createAction(UPDATE_CATEGORY_INFO_SUCCESS);
export const updateCategoryInfoFailure = createAction(UPDATE_CATEGORY_INFO_FAILURE);

export const UPDATE_ITEM_INFO = `${PREFIX}/UPDATE_ITEM_INFO`;
export const UPDATE_ITEM_INFO_SUCCESS = `${PREFIX}/UPDATE_ITEM_INFO_SUCCESS`;
export const UPDATE_ITEM_INFO_FAILURE = `${PREFIX}/UPDATE_ITEM_INFO_FAILURE`;

export const updateItemInfo = createAction(UPDATE_ITEM_INFO);
export const updateItemInfoSuccess = createAction(UPDATE_ITEM_INFO_SUCCESS);
export const updateItemInfoFailure = createAction(UPDATE_ITEM_INFO_FAILURE);

export const UPDATE_ITEMS_ORDER_INFO = `${PREFIX}/UPDATE_ITEMS_ORDER_INFO`;
export const UPDATE_ITEMS_ORDER_INFO_SUCCESS = `${PREFIX}/UPDATE_ITEMS_ORDER_INFO_SUCCESS`;
export const UPDATE_ITEMS_ORDER_INFO_FAILURE = `${PREFIX}/UPDATE_ITEMS_ORDER_INFO_FAILURE`;

export const updateItemsOrderInfo = createAction(UPDATE_ITEMS_ORDER_INFO);
export const updateItemsOrderInfoSuccess = createAction(UPDATE_ITEMS_ORDER_INFO_SUCCESS);
export const updateItemsOrderInfoFailure = createAction(UPDATE_ITEMS_ORDER_INFO_FAILURE);

export const UPDATE_CATEGORIES_ORDER_INFO = `${PREFIX}/UPDATE_CATEGORIES_ORDER_INFO`;
export const UPDATE_CATEGORIES_ORDER_INFO_SUCCESS = `${PREFIX}/UPDATE_CATEGORIES_ORDER_INFO_SUCCESS`;
export const UPDATE_CATEGORIES_ORDER_INFO_FAILURE = `${PREFIX}/UPDATE_CATEGORIES_ORDER_INFO_FAILURE`;

export const updateCategoriesOrderInfo = createAction(UPDATE_CATEGORIES_ORDER_INFO);
export const updateCategoriesOrderInfoSuccess = createAction(UPDATE_CATEGORIES_ORDER_INFO_SUCCESS);
export const updateCategoriesOrderInfoFailure = createAction(UPDATE_CATEGORIES_ORDER_INFO_FAILURE);

export const UPDATE_MODIFIERS_ORDER_INFO = `${PREFIX}/UPDATE_MODIFIERS_ORDER_INFO`;
export const UPDATE_MODIFIERS_ORDER_INFO_SUCCESS = `${PREFIX}/UPDATE_MODIFIERS_ORDER_INFO_SUCCESS`;
export const UPDATE_MODIFIERS_ORDER_INFO_FAILURE = `${PREFIX}/UPDATE_MODIFIERS_ORDER_INFO_FAILURE`;

export const updateModifiersOrderInfo = createAction(UPDATE_MODIFIERS_ORDER_INFO);
export const updateModifiersOrderInfoSuccess = createAction(UPDATE_MODIFIERS_ORDER_INFO_SUCCESS);
export const updateModifiersOrderInfoFailure = createAction(UPDATE_MODIFIERS_ORDER_INFO_FAILURE);

export const UPDATE_MODIFIER_INFO = `${PREFIX}/UPDATE_MODIFIER_INFO`;
export const UPDATE_MODIFIER_INFO_SUCCESS = `${PREFIX}/UPDATE_MODIFIER_INFO_SUCCESS`;
export const UPDATE_MODIFIER_INFO_FAILURE = `${PREFIX}/UPDATE_MODIFIER_INFO_FAILURE`;

export const updateModifierInfo = createAction(UPDATE_MODIFIER_INFO);
export const updateModifierInfoSuccess = createAction(UPDATE_MODIFIER_INFO_SUCCESS);
export const updateModifierInfoFailure = createAction(UPDATE_MODIFIER_INFO_FAILURE);

export const UPDATE_MODIFIER_GROUP_INFO = `${PREFIX}/UPDATE_MODIFIER_GROUP_INFO`;
export const UPDATE_MODIFIER_GROUP_INFO_SUCCESS = `${PREFIX}/UPDATE_MODIFIER_GROUP_INFO_SUCCESS`;
export const UPDATE_MODIFIER_GROUP_INFO_FAILURE = `${PREFIX}/UPDATE_MODIFIER_GROUP_INFO_FAILURE`;

export const updateModifierGroupInfo = createAction(UPDATE_MODIFIER_GROUP_INFO);
export const updateModifierGroupInfoSuccess = createAction(UPDATE_MODIFIER_GROUP_INFO_SUCCESS);
export const updateModifierGroupInfoFailure = createAction(UPDATE_MODIFIER_GROUP_INFO_FAILURE);

export const GET_SYNC_INVENTORY = `${PREFIX}/GET_SYNC_INVENTORY`;
export const GET_SYNC_INVENTORY_SUCCESS = `${PREFIX}/GET_SYNC_INVENTORY_SUCCESS`;
export const GET_SYNC_INVENTORY_FAILURE = `${PREFIX}/GET_SYNC_INVENTORY_FAILURE`;

export const getSyncInventory = createAction(GET_SYNC_INVENTORY);
export const getSyncInventorySuccess = createAction(GET_SYNC_INVENTORY_SUCCESS);
export const getSyncInventoryFailure = createAction(GET_SYNC_INVENTORY_FAILURE);

export const UPDATE_VARIANT_INFO = `${PREFIX}/UPDATE_VARIANT_INFO`;
export const UPDATE_VARIANT_INFO_SUCCESS = `${PREFIX}/UPDATE_VARIANT_INFO_SUCCESS`;
export const UPDATE_VARIANT_INFO_FAILURE = `${PREFIX}/UPDATE_VARIANT_INFO_FAILURE`;

export const updateVariantInfo = createAction(UPDATE_VARIANT_INFO);
export const updateVariantInfoSuccess = createAction(UPDATE_VARIANT_INFO_SUCCESS);
export const updateVariantInfoFailure = createAction(UPDATE_VARIANT_INFO_FAILURE);


function* updateCategory({ payload }) {
  const { id: categoryId, ...categoryInfo } = payload;
  const { _id: merchantId } = yield select((state) => state.merchant.business);
  try {
    const result = yield call(updateCategoryInfoApi, merchantId, categoryId, categoryInfo);
    yield put(updateCategoryInfoSuccess(result.data.data));
  } catch (e) {
    yield put(updateCategoryInfoFailure());
  }
}

function* updateItem({ payload }) {
  const { id: itemId, ...itemInfo } = payload;
  const { _id: merchantId } = yield select((state) => state.merchant.business);
  try {
    const result = yield call(updateItemInfoApi, merchantId, itemId, itemInfo);
    yield put(updateItemInfoSuccess(result.data.data));
  } catch (e) {
    yield put(updateItemInfoFailure());
  }
}

function* updateItemsOrder({ payload }) {
  try {
    const { _id: merchantId } = yield select((state) => state.merchant.business);
    const result = yield call(updateItemsOrderInfoApi, merchantId, payload);
    yield put(updateItemsOrderInfoSuccess(result.data.data));
  } catch (e) {
    yield put(updateItemsOrderInfoFailure());
  }
}

function* updateCategoriesOrder({ payload }) {
  try {
    const { _id: merchantId } = yield select((state) => state.merchant.business);
    const result = yield call(updateCategoriesOrderInfoApi, merchantId, payload);
    yield put(updateCategoriesOrderInfoSuccess(result.data.data));
  } catch (e) {
    yield put(updateCategoriesOrderInfoFailure());
  }
}

function* updateModifiersOrder({ payload }) {
  try {
    const { _id: merchantId } = yield select((state) => state.merchant.business);
    const result = yield call(updateModifiersOrderInfoApi, merchantId, payload);
    yield put(updateModifiersOrderInfoSuccess(result.data.data));
  } catch (e) {
    yield put(updateModifiersOrderInfoFailure());
  }
}

function* updateModifier({ payload }) {
  const { id: modifierId, ...modifierInfo } = payload;
  const { _id: merchantId } = yield select((state) => state.merchant.business);
  try {
    const result = yield call(updateModifierInfoApi, merchantId, modifierId, modifierInfo);
    yield put(updateModifierInfoSuccess(result.data.data));
  } catch (e) {
    yield put(updateModifierInfoFailure());
  }
}

function* updateModifierGroup({ payload }) {
  const { id: modifierGroupId, ...modifierGroupInfo } = payload;
  const { _id: merchantId } = yield select((state) => state.merchant.business);
  try {
    const result = yield call(
      updateModifierGroupInfoApi,
      merchantId,
      modifierGroupId,
      modifierGroupInfo,
    );
    yield put(updateModifierGroupInfoSuccess(result.data.data));
  } catch (e) {
    yield put(updateModifierGroupInfoFailure());
  }
}

function* SyncInventory({ payload }) {
  const { locationId } = payload;
  const { _id: merchantId } = yield select((state) => state.merchant.business);
  try {
    yield call(getSyncInventoryAPI, merchantId, locationId);
    yield call(getSyncItemsAPI, merchantId, locationId);
    yield call(getSyncModifierGroupsAPI, merchantId, locationId);

    const { data: { data: inventory } } = yield call(getInventory, merchantId);
    yield put(getSyncInventorySuccess(inventory));
  } catch (e) {
    yield put(getSyncInventoryFailure());
  }
}

function* updateVariant({ payload }) {
  const { id: variantId, itemId, ...variantInfo } = payload;
  const { _id: merchantId } = yield select((state) => state.merchant.business);
  try {
    const result = yield call(updateVariantInfoApi, merchantId, itemId, variantId, variantInfo);
    yield put(updateVariantInfoSuccess(result.data.data));
  } catch (e) {
    yield put(updateVariantInfoFailure());
  }
}

export function* watchInventory() {
  yield takeLatest(UPDATE_CATEGORY_INFO, updateCategory);
  yield takeLatest(UPDATE_ITEM_INFO, updateItem);
  yield takeLatest(UPDATE_ITEMS_ORDER_INFO, updateItemsOrder);
  yield takeLatest(UPDATE_CATEGORIES_ORDER_INFO, updateCategoriesOrder);
  yield takeLatest(UPDATE_MODIFIERS_ORDER_INFO, updateModifiersOrder);
  yield takeLatest(UPDATE_MODIFIER_INFO, updateModifier);
  yield takeLatest(UPDATE_MODIFIER_GROUP_INFO, updateModifierGroup);
  yield takeLatest(GET_SYNC_INVENTORY, SyncInventory);
  yield takeLatest(UPDATE_VARIANT_INFO, updateVariant);
}
