import http from '../../lib/http';

const API = '/api/v1';

export const fetchMerchantInfo = () => http.get(`${API}/merchants/me`);
export const listMerchantLocation = (id) => http.get(`${API}/merchants/${id}/locations`);
export const installLocation = (location, merchantId) => {
  return http.post(`${API}/merchants/${merchantId}/locations/${location.id}/install`, { data: { location } });
};
export const updateMerchantInfo = (id, merchantInfo) => http.put(`${API}/merchants/${id}`, { data: merchantInfo });
export const updateOwnerInfoApi = (id, ownerInfo) => http.put(`${API}/merchants/${id}/owner`, { data: ownerInfo });
export const updateLocationInfoApi = (id, locationId, locationInfo) => http.put(`${API}/merchants/${id}/locations/${locationId}`, { data: locationInfo });
