import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Form,
  Grid,
  Header,
  Divider,
  Message,
  Segment,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import Input from '../input';
import Toggle from '../toggle';
import validate from './validate';
import FormattedText from '../../lib/locale/formattedText';

class ModifierGroupFrom extends React.Component {
  render() {
    const {
      handleSubmit, modifierGroup, submitInfo, intl,
    } = this.props;
    return (
      <Grid textAlign="center" verticalAlign="middle">
        <Grid.Column style={{ maxWidth: '450px', marginTop: '1em' }}>
          <Segment raised>
            <Header as="h3" color="blue" style={{ textTransform: 'uppercase' }}>{modifierGroup.name}</Header>
            <Form size="large" onSubmit={handleSubmit}>
              <Divider hidden />
              <Field
                component={Input}
                name="name"
                placeholder={intl.formatMessage({ id: 'name', defaultMessage: 'Name' })}
              />
              <Field
                component={Input}
                name="alternateName"
                placeholder={intl.formatMessage({ id: 'alt-name', defaultMessage: 'Alt. Name' })}
              />
              <Field
                component={Input}
                name="description"
                placeholder={intl.formatMessage({ id: 'description', defaultMessage: 'Description' })}
              />
              <Field
                component={Input}
                name="warning"
                placeholder={intl.formatMessage({ id: 'warning', defaultMessage: 'Warning' })}
              />
              <Field
                component={Input}
                name="minimum"
                placeholder={intl.formatMessage({ id: 'minimum', defaultMessage: 'Minimum' })}
              />
              <Field
                component={Input}
                name="maximum"
                placeholder={intl.formatMessage({ id: 'maximum', defaultMessage: 'Maximum' })}
              />
              <Form.Field>
                <Field
                  component={Toggle}
                  name="hidden"
                  placeholder={intl.formatMessage({ id: 'hidden', defaultMessage: 'Hidden' })}
                />
              </Form.Field>
              {submitInfo.success && <Message color="green">{submitInfo.message}</Message>}
              {!submitInfo.success && submitInfo.message && <Message color="red">{submitInfo.message}</Message>}
              <Button color="blue" fluid size="huge">
                <FormattedText id="save" defaultMessage="Save" />
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default reduxForm({ form: 'modifierGroup', validate })(injectIntl(ModifierGroupFrom));
