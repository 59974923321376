import http from '../../lib/http';

const API = '/api/v1';

export const addLocationHourApi = ({ merchantId, locationId, hour }) => {
  return http.post(`${API}/merchants/${merchantId}/locations/${locationId}/hours`, { data: hour });
};

export const deleteLocationHourApi = ({ merchantId, locationId, hourId }) => {
  return http.delete(`${API}/merchants/${merchantId}/locations/${locationId}/hours/${hourId}`);
};

export const setNotificationSettingsApi = ({
  merchantId, locationId, email, phone,
}) => {
  return http.post(`${API}/merchants/${merchantId}/locations/${locationId}/notifications`, { data: { email, phone } });
};
