import { handleActions, combineActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  GET_MERCHANT_INFO,
  GET_MERCHANT_INFO_SUCCESS,
  GET_MERCHANT_INFO_FAILURE,
  UPDATE_LOCATION_INFO_SUCCESS,
  UPDATE_LOCATION_INFO_FAILURE,
  UPDATE_OWNER_INFO_SUCCESS,
  UPDATE_OWNER_INFO_FAILURE,
} from './merchant';
import {
  UPDATE_BUSINESS_INFO_SUCCESS,
  UPDATE_BUSINESS_INFO_FAILURE,
} from '../sagas/merchant';
import {
  UPDATE_CATEGORY_INFO_SUCCESS,
  UPDATE_CATEGORY_INFO_FAILURE,
  UPDATE_ITEMS_ORDER_INFO_SUCCESS,
  UPDATE_ITEMS_ORDER_INFO_FAILURE,
  UPDATE_CATEGORIES_ORDER_INFO_SUCCESS,
  UPDATE_CATEGORIES_ORDER_INFO_FAILURE,
  UPDATE_MODIFIERS_ORDER_INFO_FAILURE,
  UPDATE_MODIFIER_INFO_FAILURE,
  UPDATE_MODIFIER_GROUP_INFO_FAILURE,
  UPDATE_MODIFIER_INFO_SUCCESS,
  UPDATE_MODIFIER_GROUP_INFO_SUCCESS,
  GET_SYNC_INVENTORY_SUCCESS,
  GET_SYNC_INVENTORY_FAILURE,
  GET_SYNC_INVENTORY,
  UPDATE_VARIANT_INFO_SUCCESS,
  UPDATE_VARIANT_INFO_FAILURE,
  UPDATE_ITEM_INFO_SUCCESS,
  UPDATE_ITEM_INFO_FAILURE,
} from '../sagas/inventory';
import { UPDATE_ORDER_STATE_SUCCESS } from '../sagas/orders';

export const defaultState = {
  isLoading: false,
  submit: {
    success: null,
    message: null,
  },
  showLoader: false,
};

export const reducer = handleActions(
  {
    [LOCATION_CHANGE]: (state) => {
      return {
        ...state,
        submit: defaultState.submit,
      };
    },
    [GET_MERCHANT_INFO]: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [GET_MERCHANT_INFO_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    [GET_MERCHANT_INFO_FAILURE]: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    [UPDATE_OWNER_INFO_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Owner info correctly updated!',
        },
      };
    },
    [UPDATE_BUSINESS_INFO_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Business info correctly updated!',
        },
      };
    },
    [UPDATE_CATEGORY_INFO_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Category info correctly updated!',
        },
      };
    },
    [UPDATE_MODIFIER_INFO_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Modifier info correctly updated!',
        },
      };
    },
    [UPDATE_MODIFIER_GROUP_INFO_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Modifier group info correctly updated!',
        },
      };
    },
    [combineActions(
      UPDATE_ITEMS_ORDER_INFO_SUCCESS,
      UPDATE_CATEGORIES_ORDER_INFO_SUCCESS,
    )]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'New order correctly saved.',
        },
      };
    },
    [UPDATE_LOCATION_INFO_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Location info correctly updated!',
        },
      };
    },
    [UPDATE_ORDER_STATE_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Order state correctly updated!',
        },
      };
    },
    [GET_SYNC_INVENTORY_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Correctly synchronized!',
        },
        showLoader: false,
      };
    },
    [GET_SYNC_INVENTORY]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Correctly synchronized!',
        },
        showLoader: true,
      };
    },
    [UPDATE_VARIANT_INFO_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Variant correctly updated!',
        },
        showLoader: true,
      };
    },
    [UPDATE_ITEM_INFO_SUCCESS]: (state) => {
      return {
        ...state,
        submit: {
          success: true,
          message: 'Item correctly updated!',
        },
        showLoader: true,
      };
    },
    [combineActions(
      UPDATE_OWNER_INFO_FAILURE,
      UPDATE_BUSINESS_INFO_FAILURE,
      UPDATE_CATEGORY_INFO_FAILURE,
      UPDATE_ITEMS_ORDER_INFO_FAILURE,
      UPDATE_CATEGORIES_ORDER_INFO_FAILURE,
      UPDATE_MODIFIERS_ORDER_INFO_FAILURE,
      UPDATE_MODIFIER_INFO_FAILURE,
      UPDATE_MODIFIER_GROUP_INFO_FAILURE,
      UPDATE_LOCATION_INFO_FAILURE,
      GET_SYNC_INVENTORY_FAILURE,
      UPDATE_VARIANT_INFO_FAILURE,
      UPDATE_ITEM_INFO_FAILURE,
    )]: (state) => {
      return {
        ...state,
        submit: {
          success: false,
          message: 'Sorry, there was an error.',
        },
        showLoader: false,
      };
    },
  },
  defaultState,
);
