import React from 'react';
import SignUpForm from '../../components/signUpForm';
import BasicLayout from '../../components/basicLayout';

const Login = () => {
  const handleSubmit = (form) => console.log(form);

  return (
    <BasicLayout>
      <SignUpForm onSubmit={handleSubmit} />
    </BasicLayout>
  );
};

export default Login;
