/**
 * Parsing URL query string into an object
 * @param rawQueryStr
 * @returns {object}
 */
export const parseQueryUrl = (rawQueryStr) => {
  const queryObj = {};
  rawQueryStr.substring(rawQueryStr.indexOf('?') + 1, rawQueryStr.length)
    .split('&')
    .forEach((query) => {
      if (query) {
        const [key, val] = query.split('=');
        queryObj[key] = val;
      }
    });

  return queryObj;
};

/**
 * Capitalizing string
 * @params
 * @returns {string}
 */
export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Formatting day to seconds to proper time format
 * @param {number} seconds
 * @returns {string}
 */
export const daySecondsToStr = (seconds) => {
  const minute = (seconds % 3600) / 60;
  const hour = parseInt(seconds / 3600, 10);
  return `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`;
};

/**
 * Convert day number to day string
 * @param {number} dayNum
 * @returns {string}
 */
export const dayNumToStr = (dayNum) => {
  const day = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };
  return day[dayNum];
};
