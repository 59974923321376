import {
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import React from 'react';

const Loading = ({
  indeterminate, size, inverted, text,
}) => {
  return (
    <Dimmer active inverted={inverted}>
      <Loader size={size} indeterminate={indeterminate}>{text}</Loader>
    </Dimmer>
  );
};

Loading.defaultProps = {
  size: 'large',
  text: 'Loading',
  inverted: true,
  indeterminate: true,
};

export default Loading;
