import React from 'react';
import {
  Menu,
  Icon,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { isActive } from './helpers';
import * as PATH from '../../../constants/path';
import FormattedText from '../../../lib/locale/formattedText';

class MenuItems extends React.PureComponent {
  render() {
    const {
      pathname, handleItemClick, logoutUser, intl,
    } = this.props;

    return (
      <>
        <Menu.Item
          name={intl.formatMessage({ id: 'sidebar.business-info', defaultMessage: 'Business Info' })}
          route={PATH.DASHBOARD_BUSINESS}
          active={isActive(PATH.DASHBOARD_BUSINESS, pathname)}
          onClick={handleItemClick}
          color="blue"
        >
          <Icon name="building" />
          <FormattedText id="sidebar.business-info" defaultMessage="Business Info" />
        </Menu.Item>
        <Menu.Item
          name={intl.formatMessage({ id: 'sidebar.owner-info', defaultMessage: 'Owner Info' })}
          route={PATH.DASHBOARD_OWNER}
          active={isActive(PATH.DASHBOARD_OWNER, pathname)}
          onClick={handleItemClick}
          color="blue"
        >
          <Icon name="user" />
          <FormattedText id="sidebar.owner-info" defaultMessage="Owner Info" />
        </Menu.Item>
        <Menu.Item
          name={intl.formatMessage({ id: 'sidebar.locations', defaultMessage: 'Locations' })}
          route={PATH.DASHBOARD_LOCATIONS}
          active={isActive(PATH.DASHBOARD_LOCATIONS, pathname)}
          onClick={handleItemClick}
          color="blue"
        >
          <Icon name="shopping bag" />
          <FormattedText id="idebar.locations" defaultMessage="Locations" />
        </Menu.Item>
        <Menu.Item onClick={handleItemClick} route={PATH.DASHBOARD_CATEGORIES}>
          <Icon name="food" />
          <FormattedText id="sidebar.inventory" defaultMessage="Inventory" />


          <Menu.Menu>
            <Menu.Item
              name="Categories"
              route={PATH.DASHBOARD_CATEGORIES}
              active={isActive(PATH.DASHBOARD_CATEGORIES, pathname)}
              onClick={handleItemClick}
            >
              <FormattedText id="categories" defaultMessage="Categories" />
            </Menu.Item>
            <Menu.Item
              name="Items"
              route={PATH.DASHBOARD_ITEMS}
              active={isActive(PATH.DASHBOARD_ITEMS, pathname)}
              onClick={handleItemClick}
            >
              <FormattedText id="items" defaultMessage="Items" />
            </Menu.Item>
            <Menu.Item
              name="Modifier groups"
              route={PATH.DASHBOARD_MODIFIER_GROUPS}
              active={isActive(PATH.DASHBOARD_MODIFIER_GROUPS, pathname)}
              onClick={handleItemClick}
            >
              <FormattedText id="sidebar.inventory.modifier-groups" defaultMessage="Modifier Groups" />
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item
          name={intl.formatMessage({ id: 'sidebar.orders', defaultMessage: 'Orders' })}
          route={PATH.DASHBOARD_ORDERS}
          active={isActive(PATH.DASHBOARD_ORDERS, pathname)}
          onClick={handleItemClick}
          color="blue"
        >
          <Icon name="shop" />
          <FormattedText id="sidebar.orders" defaultMessage="Orders" />
        </Menu.Item>
        <Menu.Item onClick={logoutUser}>
          <Icon name="log out" />
          <FormattedText id="sidebar.logout" defaultMessage="Logout" />
        </Menu.Item>
      </>
    );
  }
}

export default injectIntl(MenuItems);
