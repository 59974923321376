import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Form,
  Grid,
  Divider,
  Message,
  Segment,
} from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import Toggle from '../toggle';

const LocationSettingsForm = ({ handleSubmit, submitInfo }) => {
  const intl = useIntl();

  return (
    <Grid verticalAlign="middle">
      <Grid.Column>
        <Segment basic>
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <Field
                component={Toggle}
                name="acceptOrder"
                placeholder={intl.formatMessage({ id: 'accept-orders', defaultMessage: 'Accept Orders' })}
              />
            </Form.Field>
            {submitInfo.success && <Message color="green">{submitInfo.message}</Message>}
            {!submitInfo.success && submitInfo.message && <Message color="red">{submitInfo.message}</Message>}

            <Divider />
            <div>
              <Button color="blue" fluid>
                <FormattedMessage id="save" defaultMessage="Save" />
              </Button>
            </div>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default reduxForm({ form: 'location' })(LocationSettingsForm);
