import React from 'react';
import {
  Button,
  Container,
  Menu,
  Responsive,
  Visibility,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { getWidth, isActive } from './helpers';
import {
  SIGN_IN,
  SIGN_UP,
} from '../../../constants/path';


export default class DesktopNav extends React.Component {
  showAuthButton = () => {
    const { isLogged, logoutUser } = this.props;
    return !isLogged
      ? (
        <>
          <Button as={Link} to={SIGN_IN}>
            Login
          </Button>
          <Button as={Link} to={SIGN_UP} primary style={{ marginLeft: '0.5em' }}>
            Sign Up
          </Button>
        </>
      )
      : <Button onClick={logoutUser}>Logout</Button>;
  }

  render() {
    const { children, pathname } = this.props;

    return (
      <Responsive
        getWidth={getWidth}
        minWidth={Responsive.onlyTablet.minWidth}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Visibility>
          <Menu
            fixed="top"
            size="large"
            pointing
            secondary
            style={{ backgroundColor: 'white' }}
          >
            <Container>
              <Menu.Item header>
                Logo
                our store
              </Menu.Item>
              <Menu.Item as="a" active={isActive('pricing', pathname)}>Pricing</Menu.Item>
              <Menu.Item position="right">
                {this.showAuthButton()}
              </Menu.Item>
            </Container>
          </Menu>
        </Visibility>
        {children}
      </Responsive>
    );
  }
}
