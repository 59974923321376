import React from 'react';
import {
  Button, Icon, Table,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import TimeFormat from '../timeFormat';
import { dayNumToStr } from '../../lib/common';

const LocationHourList = ({ location, handleDeleteHour }) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="delete" defaultMessage="Delete" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="day" defaultMessage="Day" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="start-time" defaultMessage="Start time" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="end-time" defaultMessage="End time" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {location.locationHours.map((hour) => (
          <Table.Row key={hour._id}>
            <Table.Cell>
              <Button size="tiny" negative icon onClick={() => handleDeleteHour(hour._id)}>
                <Icon name="delete" />
              </Button>
            </Table.Cell>
            <Table.Cell>{dayNumToStr(hour.day)}</Table.Cell>
            <Table.Cell>
              <TimeFormat time={hour.startTime} />
            </Table.Cell>
            <Table.Cell>
              <TimeFormat time={hour.endTime} />
            </Table.Cell>
          </Table.Row>
        ))}
        {location.locationHours.length === 0
        && (
        <Table.Row>
          <Table.Cell colSpan={4} singleLine textAlign="center">
            <FormattedMessage id="no-data" defaultMessage="No data" />
          </Table.Cell>
        </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default LocationHourList;
