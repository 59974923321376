import React from 'react';
import {
  Container,
  Divider,
  Grid,
  Header,
  List,
  Segment,
  Button,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SIGN_UP } from '../../constants/path';

function Footer() {
  return (
    <Segment
      inverted
      vertical
      style={{
        margin: '5em 0em 0em',
        padding: '2em 0em',
      }}
    >
      <Container textAlign="center">
        <Grid divided inverted>
          <Grid.Column computer={4} mobile={8}>
            <Header inverted as="h4" content="General" />
            <List link inverted>
              <List.Item as="a">Pricing</List.Item>
              <List.Item as="a">Partners</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column computer={4} mobile={8}>
            <Header inverted as="h4" content="Help" />
            <List link inverted>
              <List.Item as="a">Contact Us</List.Item>
              <List.Item as="a">FAQ</List.Item>
              <List.Item as="a">Site Map</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Header inverted as="h4" content="Join Us" />
            <p>
              Are you a merchant using one of your POS? Register with us and experience
              the convenience of our ordering system!
            </p>
            <Button as={Link} to={SIGN_UP} basic inverted color="blue">
              Sign Up
            </Button>
          </Grid.Column>
        </Grid>

        <Divider inverted section />
        <List horizontal inverted divided link size="small">
          <List.Item as="a" href="#">
            Terms and Conditions
          </List.Item>
          <List.Item as="a" href="#">
            Privacy Policy
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
}

export default Footer;
