import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { initialize } from 'redux-form';
import {
  Grid, Icon, Header, Divider, Tab,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DASHBOARD_LOCATIONS } from '../../../constants/path';
import { updateLocationInfo } from '../../../reducers/merchant';
import LocationBusinessHourForm from '../../../components/locationBusinessHourForm';
import LocationSettingsForm from '../../../components/locationSettingsForm';
import { selectLocation } from '../../../sagas/location';
import FormattedText from '../../../lib/locale/formattedText';
import NotificationForm from '../../../components/notificationForm';

const LocationDetails = ({
  selectedLocation, submit, initForm, updateLocation,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const {
      _id: id,
      acceptOrder,
      openTime,
      closeTime,
    } = selectedLocation;
    initForm({
      id,
      acceptOrder,
      openTime,
      closeTime,
    });
    dispatch(selectLocation(selectedLocation));
    /* eslint-disable-next-line */
  }, []);
  const handleSubmit = (form) => updateLocation(form);

  const { location } = useSelector((state) => state);
  const intl = useIntl();

  const panes = [
    {
      menuItem: intl.formatMessage({ id: 'settings', defaultMessage: 'Settings' }),
      render: () => (
        <Tab.Pane>
          <LocationSettingsForm
            onSubmit={handleSubmit}
            submitInfo={submit}
            location={location}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: intl.formatMessage({ id: 'business-hours', defaultMessage: 'Business Hours' }),
      render: () => (
        <Tab.Pane>
          <LocationBusinessHourForm
            onSubmit={handleSubmit}
            submitInfo={submit}
            location={location}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: intl.formatMessage({ id: 'notification', defaultMessage: 'Notification' }),
      render: () => (
        <Tab.Pane>
          <NotificationForm
            location={location}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} style={{ marginLeft: '10px', marginTop: '1em' }}>
          <Link to={DASHBOARD_LOCATIONS} style={{ color: 'black', fontSize: '16px' }}>
            <Icon name="left chevron" />
            <FormattedText id="back" defaultMessage="Back" />
          </Link>
        </Grid.Column>
        <Grid.Column width={16} style={{ marginLeft: '10px' }}>
          <Header as="h1" color="blue" textAlign="left" style={{ marginTop: '1em' }}>
            {location.name}
          </Header>
          <Divider />
          <Tab panes={panes} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { id: locationId } = ownProps.match.params;
  return {
    selectedLocation: state.merchant.locations.find(
      (location) => location._id === locationId,
    ) || {},
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initForm: (data) => dispatch(initialize('location', data)),
    updateLocation: (form) => dispatch(updateLocationInfo(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetails);
