import React from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Header,
  Image,
} from 'semantic-ui-react';
import BasicLayout from '../../components/basicLayout';
import { listMerchantLocation, installMerchantLocation, openDashboard } from '../../reducers/merchant';
import CardLocation from '../../components/cardLocation';
import Loading from '../../components/loading';
import Error from '../../components/error';

class Location extends React.Component {
  componentDidMount() {
    this.props.getMerchantLocation(this.props.match.params.businessId);
  }

  render() {
    const {
      merchant: {
        locations,
        business,
        owner: { firstName, lastName },
      },
      error,
      installLocation,
      openMerchantDashboard,
    } = this.props;
    const headerStr = `Hey ${firstName} ${lastName}, please choose the location you want to install JustStore.`;

    const mainContent = business.id || business._id ? (
      <div>
        <Header as="h1" textAlign="center">
          {headerStr}
        </Header>
        <div>
          <Image src={business.logo} size="small" centered circular />
          <Header as="h2" textAlign="center">{business.name}</Header>
          <br />
        </div>
        <Grid centered columns={3} stackable>
          {locations.map((location) => {
            return (
              <Grid.Column key={location.id}>
                <CardLocation
                  location={location}
                  installLocation={installLocation}
                  openMerchantDashboard={openMerchantDashboard}
                />
              </Grid.Column>
            );
          })}
        </Grid>
      </div>
    ) : <Loading />;

    return (
      <BasicLayout>
        {!error.isError ? mainContent : <Error message={error.message} />}
      </BasicLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMerchantLocation: (id) => dispatch(listMerchantLocation(id)),
    installLocation: (location) => dispatch(installMerchantLocation({
      merchantId: ownProps.match.params.businessId,
      location,
    })),
    openMerchantDashboard: () => dispatch(openDashboard()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
