import { handleActions } from 'redux-actions';
import {
  CREATE_LOCATION_HOUR_FAILURE,
  CREATE_LOCATION_HOUR_SUCCESS, DELETE_LOCATION_HOUR_SUCCESS,
  SELECT_LOCATION,
} from '../sagas/location';

export const defaultState = {
  error: false,
  _id: null,
  id: null,
  name: '',
  locationHours: [],
};

export const reducer = handleActions(
  {
    [SELECT_LOCATION]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [CREATE_LOCATION_HOUR_SUCCESS]: (state, { payload: { locationHours } }) => {
      return { ...state, locationHours, error: false };
    },
    [CREATE_LOCATION_HOUR_FAILURE]: (state) => {
      return { ...state, error: true };
    },
    [DELETE_LOCATION_HOUR_SUCCESS]: (state, { payload: { locationHours } }) => {
      return { ...state, locationHours, error: false };
    },
  },
  defaultState,
);
