import React, { useEffect } from 'react';
import {
  Divider, Grid, Header, Label,
} from 'semantic-ui-react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, parseQueryUrl } from '../../lib/common';
import { getOrderDetails, updateOrderState } from '../../sagas/orders';
import OrderDetailsView from '../../components/orderDetailsView';
import Loading from '../../components/loading';
import UpdateModal from '../dashboard/orderDetails/updateModal';
import orderStates from '../dashboard/orderDetails/orderStates';

const NewOrderPage = () => {
  const dispatch = useDispatch();
  const { locationId, orderId } = parseQueryUrl(useLocation().search);

  const { orders: { orderInfo } } = useSelector((state) => state);
  useEffect(() => {
    dispatch(getOrderDetails({ orderId, locationId }));
  }, [dispatch, locationId, orderId]);

  const nextStates = orderStates[orderInfo.state];
  const updateState = ({ state, orderId, locationId }) => {
    dispatch(updateOrderState({ state, orderId, locationId }));
  };

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 800, marginTop: '1rem' }}>
        {orderInfo.loading ? <Loading /> : (
          <div>
            {orderInfo.error ? <Header as="h1">Order not found!</Header> : (
              <>
                <Grid erticalAlign="middle">
                  <Grid.Column floated="left" width={5}>
                    <Header as="h1" textAlign="left">
                      New Order
                      {' '}
                      {orderInfo.title}
                      <Label color="blue" size="large" style={{ marginLeft: '10px' }}>{capitalize(orderInfo.state)}</Label>
                    </Header>
                  </Grid.Column>
                  <Grid.Column floated="right" width={5}>
                    <UpdateModal
                      nextStates={nextStates}
                      updateState={updateState}
                      orderId={orderInfo._id}
                      locationId={orderInfo.location._id}
                    />
                  </Grid.Column>
                </Grid>
                <Divider />
                <div>
                  <OrderDetailsView order={orderInfo} />
                </div>
              </>
            )}
          </div>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default NewOrderPage;
