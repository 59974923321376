import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import './App.css';
import Login from './pages/login';
import SignUp from './pages/signUp';
import Location from './pages/location';
import store from './store';
import history from './store/history';
import Install from './pages/install';
import Dashboard from './pages/dashboard';
import IntlWrapper from './lib/locale/intlWrapper';

import * as PATH from './constants/path';
import NewOrderPage from './pages/new-order';


function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IntlWrapper>
          <Switch>
            <Route exact path={PATH.SIGN_IN} component={Login} />
            <Route exact path={PATH.SIGN_UP} component={SignUp} />
            <Route exact path={PATH.INSTALL_POS} component={Install} />
            <Route path={PATH.DASHBOARD} component={Dashboard} />
            <Route exact path={PATH.LOCATION} component={Location} />
            <Route exact path={PATH.NEW_ORDER} component={NewOrderPage} />
            <Redirect from="/" to={PATH.DASHBOARD} />
          </Switch>
        </IntlWrapper>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
