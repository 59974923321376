import { put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  MOKA_START_INSTALL_SUCCESS,
} from '../reducers/install';
import {
  INSTALL_MERCHANT_LOCATION_SUCCESS,
  OPEN_DASHBOARD,
} from '../reducers/merchant';
import {
  LOGOUT_USER_SUCCESS,
} from './auth';
import { REDIRECT_TO_HOME } from './errorHandler';
import * as PATH from '../constants/path';

export const actionsList = [
  MOKA_START_INSTALL_SUCCESS,
  LOGOUT_USER_SUCCESS,
  REDIRECT_TO_HOME,
  INSTALL_MERCHANT_LOCATION_SUCCESS,
  OPEN_DASHBOARD,
];

export function* watchRedirect() {
  while (true) {
    const action = yield take(actionsList);
    let path;
    switch (action.type) {
      case MOKA_START_INSTALL_SUCCESS: {
        path = PATH.LOCATION.replace(':businessId', action.payload.businessId);
        break;
      }
      case INSTALL_MERCHANT_LOCATION_SUCCESS: {
        path = PATH.DASHBOARD;
        break;
      }
      case OPEN_DASHBOARD: {
        path = PATH.DASHBOARD;
        break;
      }
      case REDIRECT_TO_HOME:
      case LOGOUT_USER_SUCCESS:
      default:
        path = PATH.SIGN_IN;
        break;
    }
    yield put(push(path));
  }
}
