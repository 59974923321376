import React from 'react';
import {
  Table,
  Pagination,
} from 'semantic-ui-react';
import * as PATH from '../../../constants/path';
import FormattedText from '../../../lib/locale/formattedText';

const PAGE = 20;

const OrdersTable = ({
  orders, redirect, count, fetchOrders, queryString,
}) => {
  const goToOrderDetail = (e) => {
    e.preventDefault();
    if (e.target.nodeName === 'TD') {
      return redirect(`${PATH.DASHBOARD_ORDERS}/${e.target.parentNode.id}`);
    }

    return false;
  };
  const handlePageChange = (e, { activePage }) => fetchOrders(`${queryString}&page=${activePage}`);

  const pagesNumber = Math.floor(count / PAGE) + (count % PAGE ? 1 : 0);


  return (
    <Table celled selectable striped color="blue" padded>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedText id="order-id" defaultMessage="Order ID" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedText id="ordered-by" defaultMessage="Ordered By" />
          </Table.HeaderCell>
          {/* <Table.HeaderCell>Address</Table.HeaderCell> */}
          <Table.HeaderCell>
            <FormattedText id="total-cost" defaultMessage="Total Cost" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedText id="status" defaultMessage="Status" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedText id="date" defaultMessage="Date" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body onClick={goToOrderDetail}>
        {
          orders.map((order) => {
            return (
              <Table.Row key={order.id} id={order._id} style={{ cursor: 'pointer' }}>
                <Table.Cell>{`# ${order.id}`}</Table.Cell>
                <Table.Cell>{`${order.customer.firstName} ${order.customer.lastName}`}</Table.Cell>
                {/* <Table.Cell>{order.address}</Table.Cell> */}
                <Table.Cell>
                  $
                  {order.total}
                </Table.Cell>
                <Table.Cell positive={order.state === 'completed'} negative={order.state === 'rejected'}>{order.state}</Table.Cell>
                <Table.Cell>{order.createdAt}</Table.Cell>
              </Table.Row>
            );
          })
        }
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="6">
            <Pagination
              floated="right"
              boundaryRange={0}
              defaultActivePage={1}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              totalPages={pagesNumber}
              onPageChange={handlePageChange}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default OrdersTable;
