import React from 'react';
import { withRouter } from 'react-router';
import DesktopNav from './desktopNav';
import MobileNav from './mobileNav';

const ResponsiveNav = (props) => {
  const {
    children,
    location: { pathname },
    isLogged,
    logoutUser,
  } = props;
  return (
    <div>
      <DesktopNav
        pathname={pathname}
        isLogged={isLogged}
        logoutUser={logoutUser}
      >
        {children}
      </DesktopNav>
      <MobileNav
        pathname={pathname}
        isLogged={isLogged}
        logoutUser={logoutUser}
      >
        {children}
      </MobileNav>
    </div>
  );
};

export default withRouter(ResponsiveNav);
