import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  Button,
  Form,
  Grid,
  Header,
  Divider,
  Segment,
  Message,
} from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import Input from '../input';
import Dropdown from '../dropdown';
import countries from '../../constants/countries.json';
import validate from './validate';
import FormattedText from '../../lib/locale/formattedText';

const businessTypeOptions = [
  { key: 'retail', text: 'Retail', value: 'retail' },
  { key: 'restaurant', text: 'Restaurant', value: 'restaurant' },
  { key: 'food&drink', text: 'Food & Drink', value: 'food&drink' },
  { key: 'other', text: 'Other', value: 'other' },
];

const BusinessForm = ({ handleSubmit, submitInfo }) => {
  const intl = useIntl();
  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450, marginTop: '2em' }}>
        <Form size="large" onSubmit={handleSubmit}>
          <Segment raised>
            <Header as="h2" color="blue">
              <FormattedText id="form.business-info.title" defaultMessage="Business Info" />
            </Header>
            <Divider hidden />
            <Field
              component={Input}
              name="name"
              placeholder={intl.formatMessage({ id: 'form.business-info.input-name', defaultMessage: 'Name' })}
            />
            <Field
              component={Input}
              name="phone"
              placeholder={intl.formatMessage({ id: 'form.business-info.input-phone', defaultMessage: 'Phone' })}
            />
            <Field
              component={Dropdown}
              name="businessType"
              placeholder="Business type"
              options={businessTypeOptions}
            />
            <Field
              component={Input}
              name="logo"
              placeholder={intl.formatMessage({ id: 'form.business-info.input-logo', defaultMessage: 'Logo' })}
            />
            <Field
              component={Input}
              name="address1"
              placeholder={intl.formatMessage({ id: 'form.business-info.input-address1', defaultMessage: 'Address1' })}
            />
            <Field
              component={Input}
              name="address2"
              placeholder={intl.formatMessage({ id: 'form.business-info.input-address2', defaultMessage: 'Address2' })}

            />
            <Field
              component={Input}
              name="city"
              placeholder={intl.formatMessage({ id: 'form.business-info.input-city', defaultMessage: 'City' })}
            />
            <Field
              component={Input}
              name="state"
              placeholder={intl.formatMessage({ id: 'form.business-info.input-state', defaultMessage: 'State' })}
            />
            <Field
              component={Input}
              name="zip"
              placeholder={intl.formatMessage({ id: 'form.business-info.input-zip', defaultMessage: 'ZIP' })}
            />
            <Field
              component={Dropdown}
              name="country"
              placeholder="Country"
              options={countries}
              search
            />
            {submitInfo.success && <Message color="green">{submitInfo.message}</Message>}
            {!submitInfo.success && submitInfo.message && <Message color="red">{submitInfo.message}</Message>}
            <Button color="blue" fluid size="large">
              <FormattedText id="save" defaultMessage="Save" />
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default reduxForm({ form: 'business', validate })(BusinessForm);
