export const LOCATION = '/location/:businessId';
export const INSTALL_POS = '/install/:pos';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_BUSINESS = `${DASHBOARD}/business`;
export const DASHBOARD_OWNER = `${DASHBOARD}/owner`;
export const DASHBOARD_ITEMS = `${DASHBOARD}/items`;
export const DASHBOARD_CATEGORIES = `${DASHBOARD}/categories`;
export const DASHBOARD_LOCATIONS = `${DASHBOARD}/locations`;
export const DASHBOARD_CATEGORY_DETAILS = (id) => `${DASHBOARD_CATEGORIES}/${id}`;
export const DASHBOARD_ITEM_DETAILS = (itemId, categoryId) => `${DASHBOARD_CATEGORIES}/${categoryId}/items/${itemId}`;
export const DASHBOARD_MODIFIER_GROUPS = `${DASHBOARD}/modifier-groups`;
export const DASHBOARD_MODIFIER_DETAILS = (modifierId, modifierGroupId) => `${DASHBOARD_MODIFIER_GROUPS}/${modifierGroupId}/modifiers/${modifierId}`;
export const DASHBOARD_MODIFIER_GROUP_DETAILS = (modifierGroupId) => `${DASHBOARD_MODIFIER_GROUPS}/${modifierGroupId}`;
export const DASHBOARD_ORDERS = `${DASHBOARD}/orders`;
export const NEW_ORDER = '/new-order';
