import http from '../../lib/http';

const API = '/api/v1';

export const updateCategoryInfoApi = (merchantId, categoryId, categoryInfo) => http.put(
  `${API}/merchants/${merchantId}/categories/${categoryId}`,
  { data: categoryInfo },
);

export const updateItemInfoApi = (merchantId, itemId, itemInfo) => http.put(
  `${API}/merchants/${merchantId}/items/${itemId}`,
  { data: itemInfo },
);

export const updateVariantInfoApi = (merchantId, itemId, variantId, variantInfo) => http.put(
  `${API}/merchants/${merchantId}/items/${itemId}/variants/${variantId}`,
  { data: variantInfo },
);

export const getInventory = (merchantId) => http.get(`${API}/merchants/${merchantId}/inventory`);

export const updateItemsOrderInfoApi = (merchantId, items) => http.put(
  `${API}/merchants/${merchantId}/inventory/items/sort`,
  { data: { items } },
);

export const updateCategoriesOrderInfoApi = (merchantId, categories) => http.put(
  `${API}/merchants/${merchantId}/inventory/categories/sort`,
  { data: { categories } },
);

export const updateModifiersOrderInfoApi = (merchantId, modifiers) => http.put(
  `${API}/merchants/${merchantId}/inventory/modifiers/sort`,
  { data: { modifiers } },
);

export const updateModifierInfoApi = (merchantId, modifierId, modifierInfo) => http.put(
  `${API}/merchants/${merchantId}/modifiers/${modifierId}`,
  { data: modifierInfo },
);

export const getSyncInventoryAPI = (merchantId, locationId) => http.get(
  `${API}/merchants/${merchantId}/locations/${locationId}/categories/sync`,
);

export const getSyncItemsAPI = (merchantId, locationId) => http.get(
  `${API}/merchants/${merchantId}/locations/${locationId}/items/sync`,
);

export const getSyncModifierGroupsAPI = (merchantId, locationId) => http.get(
  `${API}/merchants/${merchantId}/locations/${locationId}/modifierGroups/sync`,
);

export const updateModifierGroupInfoApi = (
  merchantId,
  modifierGroupId,
  modifierGroupInfo,
) => http.put(
  `${API}/merchants/${merchantId}/modifierGroups/${modifierGroupId}`,
  { data: modifierGroupInfo },
);
