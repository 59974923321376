import { Button } from 'semantic-ui-react';
import React from 'react';

const ButtonUninstallLocation = () => {
  return (<Button basic color="red">Uninstall</Button>);
};

ButtonUninstallLocation.defaultProps = ({
  businessId: null,
  locationId: null,
});

export default ButtonUninstallLocation;
