import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  Grid,
  Header,
  Icon,
  Tab,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { updateItemInfo, updateVariantInfo } from '../../../sagas/inventory';
import { DASHBOARD_ITEMS } from '../../../constants/path';
import Variants from './variants';
import Item from './item';
import FormattedText from '../../../lib/locale/formattedText';

class ItemDetails extends React.Component {
  handleSubmit = (form) => {
    return this.props.updateItem(form);
  }

  handleSubmitVariant = (form) => {
    return this.props.updateVariantInfo({ ...form, itemId: this.props.item._id });
  }

  render() {
    const {
      submit, item, initVariantForm, initForm, intl,
    } = this.props;
    if (!item) {
      return <div>No item!</div>;
    }
    const panes = [
      {
        menuItem: intl.formatMessage({ id: 'detail', defaultMessage: 'Detail' }),
        render: () => (
          <Item
            init={initForm}
            item={item}
            submitInfo={submit}
            onSubmit={this.handleSubmit}
          />
        ),
      },
      {
        menuItem: intl.formatMessage({ id: 'variants', defaultMessage: 'Variants' }),
        render: () => (
          <Variants
            init={initVariantForm}
            variants={item.variants}
            submitInfo={submit}
            onSubmit={this.handleSubmitVariant}
          />
        ),
      },
    ];

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={{ marginLeft: '10px', marginTop: '1em' }}>
            <Link to={DASHBOARD_ITEMS} style={{ color: 'black', fontSize: '16px' }}>
              <Icon name="left chevron" />
              <FormattedText id="back" defaultMessage="Back" />
            </Link>
          </Grid.Column>
          <Grid.Column width={16} style={{ marginLeft: '10px' }}>
            <Header as="h1" color="blue" textAlign="left" style={{ marginTop: '1em' }}>
              {item.name}
            </Header>
            <Tab panes={panes} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const { itemId, categoryId } = ownProps.match.params;
  const itemCategory = state.inventory.categories
    .find((category) => category._id === categoryId) || { items: [] };
  return {
    item: itemCategory.items.find((item) => item._id === itemId) || {},
    submit: state.ui.submit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initForm: (data) => dispatch(initialize('item', data)),
    initVariantForm: (data) => dispatch(initialize('variant', data)),
    updateItem: (form) => dispatch(updateItemInfo(form)),
    updateVariantInfo: (form) => dispatch(updateVariantInfo(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemDetails));
